import React from "react";
import styled from "styled-components";
import Scoring from "../Scoring/Scoring";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <Scoring />
    </Main>
  );
}
