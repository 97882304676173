import React, { useContext, useEffect, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    flexGrow: 1,
  },
  column: {
    flexDirection: "columns",
  },
  eventTitle: {
    fontSize: 10,
    margin: 5,
  },
  table: {
    display: "table",
    width: "auto",
    fontFamily: "Helvetica",
    fontSize: 8,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    padding: "3px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  heading: {
    fontWeight: "400",
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
  },
  order: {
    width: 50,
  },
  lane: {
    width: 40,
  },
  athlete: {
    width: 110,
    display: "flex",
    flexDirection: "row",
  },
  distance: {
    width: 65,
  },
  time: {
    width: 50,
  },
  position: {
    width: 30,
  },
  points: {
    width: 120,
  },
  caption: {
    width: 240,
  },
  standings_notes: {
    border: "1 solid black",
    borderTop: "none",
    width: 250,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
  },
});

export default function Standings({
  standings,
  event,
  round,
  rounds,
  selectedRound,
  entryList,
}) {
  return (
    <>
      {standings && (
        <View>
         {selectedRound?.event?.name !== 'Atlas Stones' &&  <Text style={styles.eventTitle}>
            Standings After {round.order} of 6 Events
          </Text>
}
{selectedRound?.event?.name === 'Atlas Stones' &&  <Text style={styles.eventTitle}>
            Final Standings
          </Text>
}
          <View style={styles.table}>
            <View style={[styles.row, styles.heading]}>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text>POS</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text></Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text></Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.athlete,
                  {
                    border: "1 solid black",
                  },
                ]}
              >
                <Text>ATHLETE</Text>
              </View>

              <View
                style={[
                  styles.cell,
                  styles.points,

                  {
                    border: "1 solid black",
                    borderLeft: "none",
                    width: 50,
                  },
                ]}
              >
                <Text>POINTS</Text>
              </View>
            </View>
            {standings?.players
              ?.sort((a, b) => {
                if (a.overall_position > b.overall_position) {
                  return 1;
                } else if (a.overall_position < b.overall_position) {
                  return -1;
                }

                // Else go to the 2nd item
                if (a.player?.last_name.toUpperCase() < b.player?.last_name.toUpperCase()) {
                  return -1;
                } else if (a.player?.last_name.toUpperCase() > b.player?.last_name.toUpperCase()) {
                  return 1;
                }
                if (a.player?.first_name < b.player?.first_name) {
                  return -1;
                } else if (a.player?.first_name > b.player?.first_name) {
                  return 1;
                } else {
                  // nothing to split them
                  return 0;
                }
              })
              ?.map((row) => {
                return TableResultsRow({
                  row: row,
                  round: round,
                  selectedRound: selectedRound,
                  entryList,
                });
              })}

            <View style={styles.row}>
              <View
                style={[
                  styles.cell,
                  styles.standings_notes,

                  {
                    border: "1 solid black",
                    borderTop: "none",
                    paddingBottom: 3,
                    minHeight: 17,
                  },
                ]}
              >
                {!entryList &&
                  selectedRound.order === 5 &&
                  selectedRound.type === "qualifying" && (
                    <>
                      <View style={[{ marginTop: 3 }]}>
                        <Text>
                          Q&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qualified for WSM
                          Final
                        </Text>
                      </View>
                      <View style={{ marginTop: 3 }}>
                        <Text>
                          SO&nbsp;&nbsp;&nbsp;&nbsp;Will compete in Stone Off
                          for 2nd spot in WSM Final
                        </Text>
                      </View>
                    </>
                  )}
                {!entryList &&
                  standings?.players?.filter((p) => p.overall_status === "WD")
                    .length > 0 && (
                    <View style={[{ marginTop: 3 }]}>
                      <Text>
                        WD&nbsp;&nbsp;&nbsp;&nbsp;Withdrew from competition
                      </Text>
                    </View>
                  )}
                {!entryList &&
                  event?.scoring?.find((g) => g.group === standings.group)
                    ?.standings_notes && (
                    <View style={[{ marginTop: 3 }]}>
                      <Text>
                        {(!entryList &&
                          event &&
                          event.scoring &&
                          event.scoring.find(
                            (g) => g.group === standings.group
                          ) &&
                          event.scoring.find((g) => g.group === standings.group)
                            .standings_notes) ||
                          " "}
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

function TableResultsRow({ row, round, selectedRound, entryList }) {
  return (
    <View
      style={[
        styles.row,
        {
          minHeight: 17,
        },
      ]}
    >
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
          },
        ]}
      >
        <Text>
          {!entryList &&
            (row.overall_status !== "WD" ||
              (selectedRound.type === "final" && selectedRound?.order === 6)) &&
            row.overall_tied &&
            "T"}
          {!entryList &&
            (row.overall_status !== "WD" ||
              (selectedRound.type === "final" && selectedRound?.order === 6)) &&
            row.overall_position}
          {!entryList &&
            (selectedRound.type === "qualifying" ||
              (selectedRound.type === "final" && selectedRound?.order <= 5)) &&
            row.overall_status}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
          },
        ]}
      >
        <Text>
          {!entryList &&
            selectedRound.name !== "Final" &&
            round.order === 5 &&
            row.overall_position === 1 &&
            selectedRound.type === "qualifying" &&
            "Q"}
          {!entryList &&
            selectedRound.name !== "Final" &&
            round.order === 5 &&
            (row.overall_position === 2 || row.overall_position === 3) &&
            selectedRound.type === "qualifying" && row.overall_status !=='WD' &&
            "SO"}
          {!entryList && row.overall_status}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
            fontSize: 7,
          },
        ]}
      >
        <Text>
          {!entryList && row.player?.shirt_number
            ? row.player?.shirt_number
            : " "}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          styles.athlete,
          {
            border: "1 solid black",
            borderTop: "none",
          },
        ]}
      >
        <Text
          style={{
            textDecoration: row.overall_status === "WD" ? "line-through" : "",
          }}
        >
          {!entryList && row.player?.first_name.substring(0, 1) + ". "}
          {!entryList && " "}
          {!entryList && row.player?.last_name}
          {!entryList && " (" + row.player?.nationality + ")"}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          {
            border: "none",

            borderRight: "1 solid black",
            borderBottom: "1 solid black",
            width: 50,
          },
        ]}
      >
        <Text
          style={{
            textDecoration: row.overall_status === "WD" ? "line-through" : "",
          }}
        >
          {!entryList && row.total_points?.toFixed(1)}
        </Text>
      </View>
    </View>
  );
}
