import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import SelectUI from "../UI/Form/SelectUI";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
const Row = styled.div`
  display:flex;
`;
const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TYPES = [
  {
    value: "max_distance",
    label: "Max Distance",
  },
  {
    value: "reps",
    label: "Reps",
  },
  {
    value: "keg_toss",
    label: "Keg Toss",
  },
  {
    value: "loading",
    label: "Loading",
  },
  {
    value: "max_time",
    label: "Max Time",
  },
  {
    value: "max_weight",
    label: "Max Weight",
  },
  {
    value: "reps_and_time",
    label: "Reps & Time",
  },
  {
    value: "time",
    label: "Time",
  },
  {
    value: "time_and_distance",
    label: "Time & Distance",
  },
  {
    value: "stone_off",
    label: "Stone Off",
  },
];
export default function EventEdit({ event, onChange }) {
  const [newObject, setNewObject] = useState();
  function updateEvent(event) {
    onChange(event);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={event.name}
          title="Name"
          onChange={(e) => {
            let newEvent = { ...event, name: e.target.value };
            updateEvent(newEvent);
          }}
        />

        <SelectUI
          value={TYPES.find((t) => t.value === event?.event_type)}
          options={TYPES.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          })}
          placeholder="Select Type"
          onChange={(e) => {
            let newEvent = { ...event };
            newEvent.event_type = e;
            updateEvent(newEvent);
          }}
        />
        {(event?.event_type === "reps" ||
          event?.event_type === "reps_and_time") && (
          <Input
            value={event.unit_name ?? "Reps"}
            title="Unit Name"
            onChange={(e) => {
              let newEvent = { ...event, unit_name: e.target.value };
              updateEvent(newEvent);
            }}
          />
        )}
        {(event?.event_type === "loading" ||
          event?.event_type === "max_weight" ||
          event?.event_type === "stone_off") && (
          <>
            <Grid.Unit size={1 / 1} style={{ display: "flex" }}>
              <Input
                value={newObject?.name || ""}
                title="Object Name"
                onChange={(e) => {
                  let newObj = { name: e.target.value };
                  setNewObject(newObj);
                }}
              />
              <Button
                onClick={() => {
                  let newEvent = { ...JSON.parse(JSON.stringify(event)) };
                  if (!newEvent.objects) {
                    newEvent.objects = [];
                  }
                  newEvent.objects.push({ ...newObject });
                  setNewObject();
                  updateEvent(newEvent);
                }}
              >
                Add
              </Button>
            </Grid.Unit>
            <Grid.Unit size={1 / 1}>
              {event.objects &&
                event.objects.map((object, index) => {
                  return <Row> <Input
                  value={object?.name || ""}
                  title="Name"
                  onChange={(e) => {
                    let newEvent = { ...JSON.parse(JSON.stringify(event)) };
                  newEvent.objects[index].name = e.target.value;
                  updateEvent(newEvent);
                  }}
                /><Input
                value={object?.wr || ""}
                title="WR"
                onChange={(e) => {
                  let newEvent = { ...JSON.parse(JSON.stringify(event)) };
                newEvent.objects[index].wr = e.target.value;
                updateEvent(newEvent);
                }}
              /></Row>;
                })}
            </Grid.Unit>
          </>
        )}
        {event?.event_type === "keg_toss" && (
          <>
            <Grid.Unit size={1 / 1} style={{ display: "flex" }}>
              <Input
                value={newObject?.name || ""}
                title="height"
                onChange={(e) => {
                  let newObj = { name: e.target.value };
                  setNewObject(newObj);
                }}
              />
              <Button
                onClick={() => {
                  let newEvent = { ...JSON.parse(JSON.stringify(event)) };
                  if (!newEvent.objects) {
                    newEvent.objects = [];
                  }
                  newEvent.objects.push({ ...newObject });
                  setNewObject();
                  updateEvent(newEvent);
                }}
              >
                Add
              </Button>
            </Grid.Unit>
            <Grid.Unit size={1 / 1}>
              {event.objects &&
                event.objects.map((object) => {
                  return <div>{object.name}</div>;
                })}
            </Grid.Unit>
          </>
        )}
      </Grid.Unit>
    </Grid>
  );
}

