import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Main from "./components/Views/Main/Main";
import Hyper from "./Theme/Hyper";
import { DataProvider } from "./contexts/DataContext";
import { UIProvider } from "./contexts/UIContext";
import { SocketProvider } from "./contexts/SocketContext";
import Seasons from "./components/Views/Seasons";
import Fixtures from "./components/Views/Fixtures";
import Officials from "./components/Views/Officials";
import Countries from "./components/Views/Countries";
import Stadiums from "./components/Views/Stadiums";
import Login from "./components/Authentication/Login";
import { UserContext, UserProvider } from "./contexts/UserContext";
import Competitions from "./components/Views/Competitions";

import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Teams from "./components/Views/Teams";
import Players from "./components/Views/Players";
import Events from "./components/Views/Events";
import Groups from "./components/Views/Groups";
import Schedule from "./components/Views/Schedule";
import Scoring from "./components/Views/Scoring";
import Display from "./components/Display/Display";
import Notes from "./components/Notes/Notes";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PROJECT_SERVER + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
    }
  }
`;
function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <ThemeProvider theme={Hyper}>
          <UserProvider>
            <UIProvider>
              <DataProvider>
                <SocketProvider>
                  <Routes />
                </SocketProvider>
              </DataProvider>
            </UIProvider>
          </UserProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

function Routes() {
  const { user, setUserToken } = useContext(UserContext);
  const {
    loading,
    error,
    data,
    refetch: refetchLogin,
  } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
    client: user_client,
  });

  useEffect(() => {
    if (data) {
      setUserToken(data?.me?.token);
    }
  }, [data]);

  return (
    <>
      {!data?.me?.token && (
        <Login
          loggedIn={() => {
            refetchLogin();
          }}
        />
      )}
      {data?.me?.token && (
        <Switch>
          <Route exact path="/">
            <Redirect to="/wsm/fixtures" />
          </Route>
          <Route path="/wsm/players">
            <Main>
              <Players />
            </Main>
          </Route>
          <Route path="/wsm/competitions">
            <Main>
              <Competitions />
            </Main>
          </Route>
          <Route path="/wsm/events">
            <Main>
              <Events />
            </Main>
          </Route>
          <Route path="/wsm/groups">
            <Main>
              <Groups />
            </Main>
          </Route>
          <Route path="/wsm/schedule">
            <Main>
              <Schedule />
            </Main>
          </Route>
          <Route path="/wsm/countries">
            <Main>
              <Countries />
            </Main>
          </Route>
          <Route path="/wsm/scoring">
            <Main>
              <Scoring />
            </Main>
          </Route>
          <Route path="/wsm/display">
            <Main>
              <Display />
            </Main>
          </Route>
          <Route path="/wsm/Notes">
          <Main>
            <Notes />
            </Main>
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      )}
    </>
  );
}
export default App;
