import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import EventEditor from "../Modals/EventEditor";
import { Button } from "../../Theme/Hyper";
import { UIContext } from "../../contexts/UIContext";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function Events() {
  const { getEvent } = useContext(DataContext);
  const [events, setEvents] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const { selectedCompetition } = useContext(UIContext);
  useEffect(() => {
    getData();
  }, [selectedCompetition]);

  function getData() {
    getEvent().then((data) => {
      setEvents(
        [
          ...(data?.filter(
            (e) => e.competition?._id === selectedCompetition?._id
          ) || []),
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }
  return (
    <>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedEvent({ competition: selectedCompetition._id });
        }}
      >
        + Create Event
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Event List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>Name</TH>
                <TH>Description</TH>
                <TH>Event Type</TH>
              </tr>
            </thead>
            <tbody>
              {events &&
                events.map((event) => {
                  return (
                    <tr
                      onClick={() => {
                        setSelectedEvent(event);
                      }}
                    >
                      <td>{event._id}</td>
                      <td>{event.name}</td>
                      <td>{event.desciprtion}</td>
                      <td>{event.event_type}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedEvent && (
        <EventEditor
          event={selectedEvent}
          selectEvent={(s) => {
            setSelectedEvent(s);
          }}
          onCancel={() => {
            setSelectedEvent();
          }}
          onSave={() => {
            setSelectedEvent();
            getData();
          }}
        />
      )}
    </>
  );
}
