import React, { useContext, useEffect, useState } from "react";
import { Button, H1, H2, ScrollBar } from "../../Theme/Hyper";
import styled from "styled-components";
import Kits from "./Kits";
import Squad from "./Squad";
import moment from "moment";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Player from "./Player";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Input from "../UI/Form/Input";
import SavePanel from "../UI/Form/SavePanel";
import Grid from "styled-components-grid";
import CoachEditor from "../Modals/CoachEditor";
import { DataContext } from "../../contexts/DataContext";
import { SocketContext } from "../../contexts/SocketContext";
import { UIContext } from "../../contexts/UIContext";
import PlayerEditor from "../Modals/PlayerEditor";
import Variables from "./Variables";
import StaffEditor from "../Modals/StaffEditor";
import BarLoader from "react-spinners/BarLoader";
import { SketchPicker } from "react-color";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { CgColorPicker } from "react-icons/cg";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ConfirmModal from "../Modals/ConfirmModal";
import SelectUI from "../UI/Form/SelectUI";

const TEAM_BY_ID = gql`
  query TeamByID($id: String!) {
    teamById(id: $id) {
      _id
      name
      short_name
      variables
      code
      stadium {
        _id
        name
      }
    }
  }
`;

const DELETE_TEAM = gql`
  mutation DeleteTeam($id: String!) {
    deleteTeam(id: $id) {
      _id
    }
  }
`;
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 250px;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const ActionPanel = styled.div`
  display: flex;
  border-top: 1px solid #dadde1;
  width: 100%;
  margin-bottom: 1em;
  padding: 1em;

  box-sizing: border-box;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;
const Tabs = styled.div`
  display: flex;
`;

const Tab = styled(Link)`
  background-color: ${(props) => (props.selected ? "#fff" : "")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  color: #606770;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const Popup = styled.div`
  position: absolute;
  z-index: 100;
`;

export default function Team({ style, game, fixtureId, onUpdate }) {
  const { selectedSeason } = useContext(UIContext);
  const { getTeams, getCoach, updateTeam, getSquad, updateKit, getStadium } =
    useContext(DataContext);
  const { sendData } = useContext(SocketContext);
  const [view, setView] = useState("kits");
  const [savingState, setSavingState] = useState(0);
  const [update, setUpdate] = useState(false);
  const [showEditCoach, setShowEditCoach] = useState(false);
  const [showStaffEdit, setShowStaffEdit] = useState(false);
  const [team, setTeam] = useState();
  const [squad, setSquad] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [coach, setCoach] = useState();
  let { teamId, seasonId } = useParams();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [loading, setLoading] = useState(true);
  const [showActive, setShowActive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [getTeam, { data }] = useLazyQuery(TEAM_BY_ID);
  const [deleteTeam, { data: deleteTeamData }] = useMutation(DELETE_TEAM);
  const history = useHistory();
  useEffect(() => {
    if (teamId) {
      getTeam({ variables: { id: teamId } });
      getStadium().then((data) => {
        setStadiums(
          [...(data || [])].sort((a, b) => {
            if (a.opta_name < b.opta_name) {
              return -1;
            }
            if (a.opta_name > b.opta_name) {
              return 1;
            }
            return 0;
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (data) {
      setTeam({ ...data.teamById });
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [teamId, seasonId]);

  function save() {
    setSavingState("SAVING");

    updateTeam(team).then((data) => {
      onUpdate();
      setTimeout(() => {
        setSavingState("SAVED");
        setTimeout(() => {
          setSavingState(0);
        }, 1000);
      }, 1000);
    });
  }

  async function getData() {
    await getSquad({
      teamId: teamId,
      seasonId: seasonId,
    })
      .then((data) => {
        setSquad(data);
        setCoach(data?.find((d) => d.type === "coach") || { type: "coach" });
      })
      .catch((err) => {
        setSquad();
      });
    setLoading(false);
  }

  let team_type = game?.home_team?._id === teamId ? "home" : "away";

  return (
    <Main style={style}>
      {team && (
        <>
          <Grid>
            {game && (
              <Grid.Unit size={3 / 3}>
                <Panel>
                  <PanelTitle title="This Game"></PanelTitle>
                  <PanelContent>
                    <BarLoader loading={loading} color={"#36D7B7"} size={150} />

                    <>
                      <Button
                        green={game && game[team_type + "_team_kit"] === "home"}
                        onClick={() => {
                          updateKit({
                            id: fixtureId,
                            team: team_type,
                            kit: "home",
                          });
                        }}
                      >
                        Home Kit
                      </Button>
                      <Button
                        green={game && game[team_type + "_team_kit"] === "away"}
                        onClick={() => {
                          updateKit({
                            id: fixtureId,
                            team: team_type,
                            kit: "away",
                          });
                        }}
                      >
                        Away Kit
                      </Button>
                      <Button
                        green={
                          game && game[team_type + "_team_kit"] === "third"
                        }
                        onClick={() => {
                          updateKit({
                            id: fixtureId,
                            team: team_type,
                            kit: "third",
                          });
                        }}
                      >
                        Third Kit
                      </Button>
                    </>
                  </PanelContent>
                </Panel>
              </Grid.Unit>
            )}
            <Grid.Unit size={3 / 3} style={{ marginTop: "1em" }}>
              <Panel>
                <PanelTitle title="Team Details"></PanelTitle>
                <PanelContent>
                  <BarLoader loading={loading} color={"#36D7B7"} size={150} />
                  {!loading && (
                    <Grid style={{ width: "100%" }}>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <Input
                          value={team.name || ""}
                          title="Club Name"
                          onChange={(e) => {
                            team.name = e.target.value;
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <Input
                          value={team.short_name || ""}
                          title="Short Name"
                          onChange={(e) => {
                            team.short_name = e.target.value;
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <Input
                          value={team.code || ""}
                          title="Clock Name"
                          onChange={(e) => {
                            team.code = e.target.value;
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <FileUpload
                          id="badge"
                          label="Badge"
                          value={team?.variables?.badge || ""}
                          onChange={(filename) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                badge: filename,
                              },
                            });
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <FileUpload
                          id="altbadge"
                          label="Secondary Badge"
                          value={team?.variables?.alt_badge || ""}
                          onChange={(filename) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                alt_badge: filename,
                              },
                            });
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <FileUpload
                          id="thirdbadge"
                          label="Third Badge"
                          value={team?.variables?.third_badge || ""}
                          onChange={(filename) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                third_badge: filename,
                              },
                            });
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <VideoUpload
                          label="Video Badge"
                          value={team?.variables?.video_badge || ""}
                          onChange={(filename) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                video_badge: filename,
                              },
                            });
                          }}
                        />
                      </Grid.Unit>

                      {/* <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <Input
                          value={
                            (team.variables &&
                              team.variables["lineup_intro"]) ||
                            ""
                          }
                          title="Lineup Intro"
                          onChange={(e) => {
                            if (!team.variables) {
                              team.variables = {};
                            }
                            team.variables["lineup_intro"] = e.target.value;
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit> */}
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <SelectUI
                          value={
                            (team?.stadium && {
                              value: team?.stadium,
                              label:
                                team?.stadium?.name || team?.stadium?.opta_name,
                            }) ||
                            null
                          }
                          options={stadiums?.map((stadium) => ({
                            value: stadium,
                            label: stadium?.name || stadium?.opta_name,
                          }))}
                          title="Stadium"
                          placeholder="Select Stadium"
                          onChange={(stadium) => {
                            setTeam({
                              ...team,
                              stadium: stadium,
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>

                      <Grid.Unit
                        size={{ tablet: 1, desktop: 1 / 3 }}
                      ></Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Home kit BG colour"
                          value={team.variables?.home_kit_bg || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                home_kit_bg: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>

                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Home kit text colour"
                          value={team.variables?.home_kit_text || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                home_kit_text: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit
                        size={{ tablet: 1, desktop: 1 / 3 }}
                      ></Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Away kit BG colour"
                          value={team.variables?.away_kit_bg || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                away_kit_bg: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Away kit text colour"
                          value={team.variables?.away_kit_text || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                away_kit_text: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit
                        size={{ tablet: 1, desktop: 1 / 3 }}
                      ></Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Third kit BG colour"
                          value={team.variables?.third_kit_hex || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                third_kit_hex: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 3 }}>
                        <ColourPicker
                          title="Third kit text colour"
                          value={team.variables?.third_kit_bg || ""}
                          onChange={(value) => {
                            setTeam({
                              ...team,
                              variables: {
                                ...team.variables,
                                third_kit_bg: value,
                              },
                            });
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit
                        size={{ tablet: 1, desktop: 1 / 3 }}
                      ></Grid.Unit>
                    </Grid>
                  )}
                </PanelContent>
                <SavePanel
                  showDelete={!fixtureId}
                  state={savingState}
                  onDelete={() => {
                    setShowDelete(true);
                  }}
                  onSave={() => {
                    save();
                  }}
                  style={{ marginLeft: "auto" }}
                />
              </Panel>
            </Grid.Unit>

            {/* <Grid.Unit size={1 / 3}>
              <Panel>
                <PanelTitle title="Badge"></PanelTitle>
                <PanelContent>
                  <img
                    height={"80px"}
                    src={"/TEAM_BADGES/" + team.name + ".png"}
                    onError={(e) => {
                      e.target.src = "/BadgePlaceholder.png";
                    }}
                  />
                </PanelContent>
              </Panel>
            </Grid.Unit> */}
          </Grid>
          <Grid style={{ marginTop: "1em" }}>
            <Grid.Unit size={2 / 3}>
              <Panel>
                <PanelTitle title="Squad">
                  <Row>
                    {/* Show players not active:
                    <input
                      style={{ marginRight: ".5em" }}
                      type="checkbox"
                      checked={showActive}
                      onChange={(e) => {
                        setShowActive(!showActive);
                      }}
                    /> */}
                    <Button
                      onClick={() => {
                        setSelectedPlayer({});
                      }}
                    >
                      Create Player
                    </Button>
                  </Row>
                </PanelTitle>
                <PanelContent>
                  <Squad
                    team={team}
                    getData={getData}
                    squad={squad}
                    showActive={showActive}
                  />
                </PanelContent>
              </Panel>
            </Grid.Unit>
            <Grid.Unit size={1 / 3}>
              <Grid>
                <Grid.Unit size={1 / 1}>
                  <Panel>
                    <PanelTitle title={"Manager"}>
                      <Button
                        onClick={() => {
                          setShowEditCoach(true);
                        }}
                      >
                        Edit
                      </Button>
                    </PanelTitle>
                    <PanelContent>
                      {coach?.player?.first_name} {coach?.player?.last_name}
                      <PlayerEditor
                        selectedSeason={seasonId}
                        team={team}
                        player={showEditCoach && coach}
                        selectPlayer={(p) => {
                          if (p) {
                            setCoach(p);
                          }
                        }}
                        onCancel={() => {
                          setShowEditCoach(false);
                        }}
                        onSave={() => {
                          getData();
                          setShowEditCoach(false);
                        }}
                      />
                    </PanelContent>
                  </Panel>
                </Grid.Unit>
                {/* <Grid.Unit size={1 / 1} style={{ marginTop: "1em" }}>
                  <Panel>
                    <PanelTitle title={"Additional Staff"}>
                      <Button
                        onClick={() => {
                          setShowStaffEdit({});
                        }}
                      >
                        + Add Staff
                      </Button>
                    </PanelTitle>
                    <PanelContent>
                      <Table>
                        <thead>
                          <tr>
                            <TH>First Name</TH>
                            <TH>Last Name</TH>
                            <TH>Caption</TH>
                          </tr>
                        </thead>
                        <tbody>
                          {team.variables?.staff &&
                            team.variables?.staff?.map((staff) => {
                              return (
                                <tr
                                  onClick={() => {
                                    setShowStaffEdit(staff);
                                  }}
                                >
                                  <td>{staff.first_name}</td>
                                  <td>{staff.last_name}</td>
                                  <td>{staff.title}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </PanelContent>
                    <StaffEditor
                      team={team}
                      staffMember={showStaffEdit}
                      onCancel={() => {
                        setShowStaffEdit(false);
                      }}
                      onSave={(t) => {
                        setShowStaffEdit(false);
                        updateTeam(t).then((data) => {
                          getData();
                        });
                      }}
                    />
                  </Panel>
                </Grid.Unit> */}
              </Grid>
            </Grid.Unit>
          </Grid>
        </>
      )}
      <PlayerEditor
        selectedSeason={seasonId}
        team={team}
        player={selectedPlayer}
        selectPlayer={(p) => {
          setSelectedPlayer(p);
          getData();
        }}
        onCancel={() => {
          setSelectedPlayer();
        }}
        onSave={() => {
          getData();
          setSelectedPlayer();
          onUpdate();
        }}
      />

      <ConfirmModal
        title="Are you sure you want to delete this team?"
        show={showDelete}
        onConfirm={() => {
          deleteTeam({ variables: { id: teamId } });
          setShowDelete(false);
          onUpdate();
          history.push("/wsm/teams");
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
      ></ConfirmModal>
    </Main>
  );
}
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
function ColourPicker({ value, onChange, title }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={title}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => setOpen(!open)}
        >
          {!value && <CgColorPicker />}
        </Swatch>

        {open && (
          <Popup>
            <Cover onClick={handleClose} />
            <SketchPicker
              color={value || "#fff"}
              onChange={(colour) => {
                onChange(colour.hex);
              }}
            />
          </Popup>
        )}
      </div>
    </ColourContainer>
  );
}

function FileUpload({ value, onChange, label, id }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url = process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    fetch(`${project_serevr_url}/api/assets/images/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(files[0].name.replace(/ /g, "_"));
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        id={id}
        type="file"
        name="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById(id).click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <img
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/images/" +
                userId +
                "/" +
                value
              }
              alt="Team Badge"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}

function VideoUpload({ value, onChange, label }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url = process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    fetch(`${project_serevr_url}/api/assets/videos/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(files[0].name.replace(/ /g, "_"));
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <input
        id="video-input"
        type="file"
        name="file"
        accept="video/*"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById("video-input").click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <video
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/videos/" +
                userId +
                "/" +
                value
              }
              alt="Team Badge"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}
