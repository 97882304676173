import React, { useContext, useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";

import { UIContext } from "../../contexts/UIContext";
import EventList from "./EventList";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { Button } from "../../Theme/Hyper";
import DisplayControl from "./DisplayControl";

const Main = styled.div`
  margin-top: 52px;
  margin-bottom: 52px;
`;
const Row = styled.div`
  display: flex;
`;
export default function Display() {
  const { getRound, getGroup, getDisplay, updateDisplay } =
    useContext(DataContext);

  const { selectedCompetition } = useContext(UIContext);
  const [rounds, setRounds] = useState();
  const [groups, setGroups] = useState();
  const [display, setDisplay] = useState();
  useEffect(() => {
    getData();
  }, [selectedCompetition]);

  function getData() {
    getDisplay().then((data) => {
      setDisplay(
        data?.find((d) => d.competition?._id === selectedCompetition?._id)
      );
    });
    getRound().then((data) => {
      setRounds(
        JSON.parse(
          JSON.stringify([
            ...(data.filter(
              (g) => g.competition?._id === selectedCompetition?._id
            ) || []),
          ])
        )
      );
    });
    getGroup().then((data) => {
      setGroups(
        JSON.parse(
          JSON.stringify(
            [...(data || [])]
              .filter((g) => g.competition?._id === selectedCompetition?._id)
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
          )
        )
      );
    });
  }

  let match = useRouteMatch();

  return (
    <Main>
      <EventList rounds={rounds} selectedRound={display?.round} />
      <div style={{ marginLeft: "260px", width: "100%" }}>
        <div>
          <Panel style={{ marginTop: "1em" }}>
            <PanelTitle title={"Display"}></PanelTitle>
            <PanelContent>
              <Switch>
                <Route path={`${match.path}/:roundId`}>
                  <DisplayControl
                    rounds={rounds}
                    display={display}
                    updateDisplay={updateDisplay}
                    groups={groups}
                    getData={getData}
                  />
                </Route>
              </Switch>
            </PanelContent>
          </Panel>
        </div>
      </div>
    </Main>
  );
}
