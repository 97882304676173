import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  width: 100%;
`;
const InputField = styled.input`
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #14ec8e;
    outline: none;
  }
`;

const Label = styled.label`
  display: flex;
  font-size: 0.8em;
  margin-bottom: 0.2em;
`;
export default function NumberInput({
  value,
  placeholder,
  title,
  onChange,
  format,
}) {
  return (
    <Main>
      {title && <Label>{title}</Label>}
      <NumberFormat
        customInput={InputField}
        format={format}
        mask="#"
        value={value}
        onChange={onChange}
        type="search"
      />
    </Main>
  );
}
