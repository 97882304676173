import React, { useCallback, useContext, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";

import PlayerEdit from "../Player/PlayerEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function PlayerEditor({
  player,
  selectPlayer,
  team,
  selectedSeason,
  onSave,
  onCancel,
}) {
  const { updatePlayer, deletePlayer } = useContext(DataContext);
  useEffect(() => {
    let func = (e) => {
      if (e.keyCode === 13) {
        saveData();
      }
    };
    window.document.addEventListener("keydown", func);
    return () => {
      window.document.removeEventListener("keydown", func);
    };
  }, [player]);

  const saveData = useCallback(() => {
    let promises = [];

    promises.push(
      updatePlayer({
        ...player,
      })
    );
    Promise.all(promises)
      .then(() => {
        selectPlayer();
        onSave();
      })
      .catch(() => {
        selectPlayer();
        onSave();
      });
  }, [player]);

  return (
    <Modal
      style={{ width: "70%" }}
      show={player}
      onCancel={() => {
        selectPlayer();
        onCancel();
      }}
    >
      {player && (
        <Panel>
          <PanelTitle title={"Player Edit"}>
            <div>
              {player._id} - {player?.player?._id}
            </div>
          </PanelTitle>
          <PanelContent>
            <PlayerEdit
              team={team}
              player={{ ...player }}
              onChange={(p) => {
                selectPlayer(p);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={true}
            onDelete={() => {
              deletePlayer({ playerId: player._id }).then(() => {
                selectPlayer();
                onSave();
              });
            }}
            onSave={() => {
              saveData();
            }}
            onCancel={() => {
              selectPlayer();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
