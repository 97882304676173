import React, { useContext, useState } from "react";

import styled from "styled-components";
import Headshot from "../UI/Profile/Headshot";
import { BsExclamationDiamond } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Button } from "../../Theme/Hyper";
import ConfirmModal from "../Modals/ConfirmModal";
import { DataContext } from "../../contexts/DataContext";
import Image from "../UI/Image";

const Main = styled.div`
  border-color: #dee2e6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px !important;
  width: 100%;
  overflow: hidden;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  th:nth-child(1) {
    width: 65px;
  }
  th:nth-child(2) {
    width: 65px;
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
  td.icons {
    padding: 0px;
    font-size: 20px;
    padding-top: 3px;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Alert = styled(BsExclamationDiamond)`
  color: red;
  margin-right: 10px;
`;

const Off = styled(IoIosArrowDown)`
  color: #db0a41;
  font-size: 20px;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
  font-size: 20px;
`;
export default function Lineup({
  squad,
  selectPlayer,
  showNumber,
  showTeam,
  team,
  onChange,
  showActive,
}) {
  const [sort, setSort] = useState(0);
  const [merge, setMerge] = useState(false);
  const [mergePlayer, setMergePlayer] = useState();
  const [mergePlayer2, setMergePlayer2] = useState();
  const [confirmMerge, setConfirmMerge] = useState(false);
  const { mergePlayers } = useContext(DataContext);

  return (
    <Main>
      <Table showNumber={showNumber}>
        <thead>
          <tr>
            <TH>#</TH>
            <TH>First</TH>
            <TH>Last</TH>
            <TH>First Upper</TH>
            <TH>Last Upper</TH>
            {showTeam && <TH>Team</TH>}
            <TH>Country</TH>
            <TH>Position</TH>
            {/* <TH>Active</TH> */}
            <TH></TH>
          </tr>
        </thead>
        <tbody>
          {squad
            ?.filter(
              (l) =>
                l.type === "player" &&
                (l.active === true || (showActive && l.active === false))
            )
            ?.sort((a, b) => {
              if (showNumber) {
                return (a.order || 999) - (b.order || 999);
              } else {
                return (a.shirt_number || 999) - (b.shirt_number || 999);
              }
            })
            ?.slice(0, showNumber && 15)
            ?.map((squad, index) => {
              let player = squad.player;
              return (
                <tr
                  key={index}
                  onClick={() => {
                    selectPlayer(squad);
                  }}
                >
                  <td>{squad.shirt_number || squad.opta_shirt_number}</td>
                  <td>{player.first_name}</td>
                  <td>{player.last_name}</td>
                  <td>{player.first_name_upper}</td>
                  <td>{player.last_name_upper}</td>
                  {showTeam && <td>{player.team && player.team.name}</td>}
                  <td>
                    {/* <Image
                        style={{ height: "20px", width: "auto" }}
                        url={
                          "__SKY_SPORTS/GLOBALS/FLAGS/" +
                          ((player.nationality &&
                            player.nationality.code.toLowerCase()) ||
                            "")
                        }
                        placeholder={""}
                      /> */}
                    {(player.nationality && player.nationality.opta_code) || ""}
                  </td>
                  <td>{squad.position || ""}</td>
                  {/* <td>{squad?.active ? "yes" : "no"}</td> */}

                  <td>
                    {player && player.subbed && player.order <= 15 && <On />}
                    {player && player.subbed && player.order > 15 && <Off />}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {showNumber && (
        <Table showNumber={showNumber}>
          <thead>
            <tr>
              {showNumber && (
                <TH style={{ textAlign: "center" }}>Substitutes</TH>
              )}
              <TH></TH>
              <TH></TH>
              <TH></TH>
              <TH></TH>
              {showTeam && <TH></TH>}
              <TH></TH>
              <TH style={{ width: "25px" }}></TH>
            </tr>
          </thead>
          <tbody>
            {squad &&
              squad
                .filter((l) => l.type === "player")
                .sort((a, b) => {
                  if (showNumber) {
                    return (a.order || 999) - (b.order || 999);
                  } else {
                    return (a.shirt_number || 999) - (b.shirt_number || 999);
                  }
                })
                .slice(15)
                .map((player, index) => {
                  return (
                    <tr
                      onClick={() => {
                        selectPlayer(player);
                      }}
                    >
                      {/* {showNumber && <td>{player.order}</td>} */}
                      <td>
                        {player._id && team && (
                          <Headshot
                            image={
                              "/" +
                              (team.name || player.team) +
                              "/Home/" +
                              player.head_shots.find(
                                (p) => p.type === "standard"
                              ).url
                            }
                          />
                        )}
                      </td>
                      <td>{player.shirt_number}</td>
                      <td>{player.first_name}</td>
                      <td>{player.last_name}</td>
                      {showTeam && <td>{player.team && player.team.name}</td>}
                      <td>
                        {(player.nationality && player.nationality.code) || ""}
                      </td>
                      <td>{player.position || ""}</td>
                      <td></td>
                      <td></td>
                      <td>
                        {player && player.subbed && player.order <= 15 && (
                          <On />
                        )}
                        {player && player.subbed && player.order > 15 && (
                          <Off />
                        )}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      )}
      <ConfirmModal
        title="Are you sure you want to merge players?"
        show={confirmMerge}
        onConfirm={() => {
          mergePlayers({
            manual_player: mergePlayer,
            opta_player: mergePlayer2,
          }).then(() => {
            onChange();
            setMerge(false);
            setConfirmMerge(false);
          });
        }}
        onCancel={() => {
          setMerge(false);
          setConfirmMerge(false);
        }}
      ></ConfirmModal>
    </Main>
  );
}
