import React, { useContext, useEffect, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    flexGrow: 1,
  },
  column: {
    flexDirection: "columns",
  },
  eventTitle: {
    fontSize: 10,
    margin: 5,
  },
  table: {
    display: "table",
    width: "auto",
    fontFamily: "Helvetica",
    fontSize: 8,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    padding: "3px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  heading: {
    fontWeight: "400",
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
  },
  order: {
    width: 50,
  },
  lane: {
    width: 40,
  },
  athlete: {
    width: 140,
    display: "flex",
    flexDirection: "row",
  },
  distance: {
    width: 65,
  },
  time: {
    width: 50,
  },
  position: {
    width: 30,
  },
  points: {
    width: 120,
  },
  caption: {
    width: 230,
  },
  standings_notes: {
    border: "1 solid black",
    borderTop: "none",
    width: 230,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
  },
});

export default function FinalStandings({
  standings,
  event,
  round,
  rounds,
  selectedRound,
  entryList,
}) {
  return (
    <>
      {standings && (
        <View>
          <Text style={styles.eventTitle}>Final Standings</Text>
          <View style={styles.table}>
            <View style={[styles.row, styles.heading]}>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text>POS</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text></Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.position,

                  {
                    border: "1 solid black",
                    borderRight: "none",
                  },
                ]}
              >
                <Text></Text>
              </View>

              <View
                style={[
                  styles.cell,
                  styles.athlete,
                  {
                    border: "1 solid black",
                  },
                ]}
              >
                <Text>ATHLETE</Text>
              </View>
            </View>
            {standings?.players
              ?.sort((a, b) => {
                //below hack for stone off when bishop WD before stones
                // stone off winner automatically gets 2nd and runner up 3rd
                // WD gets 4th
                if (a?.player?.last_name === 'Bishop') {
a.overall_position = 4
                }
                if (b?.player?.last_name === 'Bishop') {
                  b.overall_position = 4
                                  }
                                  if (a?.player?.last_name === 'Evans') {
                                    a.overall_position = 3
                                                    }
                                                    if (b?.player?.last_name === 'Evans') {
                                                      b.overall_position = 3
                                                                      }
                                                                      if (a?.player?.last_name === 'Smaukstelis') {
                                                                        a.overall_position = 2
                                                                                        }
                                                                                        if (b?.player?.last_name === 'Smaukstelis') {
                                                                                          b.overall_position = 2
                                                                                                          }
                if (a?.overall_position > b?.overall_position) {
                  return 1;
                } else if (a?.overall_position < b?.overall_position) {
                  return -1;
                }

                let player_a = a.player;
                let player_b = b.player;

                if (player_a?.last_name > player_b?.last_name) {
                  return 1;
                } else if (player_a?.last_name < player_b?.last_name) {
                  return -1;
                }
                if (player_a?.first_name > player_b?.first_name) {
                  return 1;
                } else if (player_a?.first_name < player_b?.first_name) {
                  return -1;
                }
                return 0;
              })
              ?.map((row) => {
                return TableResultsRow({
                  row: row,
                  round: round,
                  selectedRound: selectedRound,
                  entryList,
                });
              })}
            {selectedRound?.order === 6 && (
              <View style={styles.row}>
                <View
                  style={[
                    styles.cell,
                    styles.caption,

                    {
                      border: "1 solid black",
                      borderTop: "none",
                    },
                  ]}
                >
                  <Text>
                    Q&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qualified
                    for WSM Final
                  </Text>
                  {standings?.players?.filter((p) => p.overall_status === "WD")
                    .length > 0 && (
                    <Text style={{ marginTop: 2 }}>
                      WD&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Withdrew from competition
                    </Text>
                  )}
                </View>
              </View>
            )}
            <View style={styles.standings_notes}>
              <Text>
                {(!entryList &&
                  event &&
                  event.scoring &&
                  event.scoring.find((g) => g.group === standings.group) &&
                  event.scoring.find((g) => g.group === standings.group)
                    .standings_notes) ||
                  " "}
              </Text>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

function TableResultsRow({ row, selectedRound, entryList }) {
  return (
    <View style={[styles.row, { minHeight: 17 }]}>
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
          },
        ]}
      >
        <Text>
          {!entryList && row.overall_tied && "T"}
          {!entryList && row.overall_position}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
          },
        ]}
      >
        <Text>
          {!entryList &&
            selectedRound.order === 6 &&
            (row.overall_position === 1 || row.overall_position === 2) && row.overall_status !=='WD' &&
            "Q"}
          {!entryList && row.overall_status}
        </Text>
      </View>
      <View
        style={[
          styles.cell,
          styles.position,
          {
            border: "none",
            borderLeft: "1 solid black",
            borderBottom: "1 solid black",
            fontSize: 7,
          },
        ]}
      >
        <Text>
          {!entryList && row.player?.shirt_number
            ? row.player?.shirt_number
            : " "}
        </Text>
      </View>

      <View
        style={[
          styles.cell,
          styles.athlete,
          {
            border: "1 solid black",
            borderTop: "none",
          },
        ]}
      >
        <Text
          style={{
            textDecoration: row.withdrew === 1 && false ? "line-through" : "",
          }}
        >
          {!entryList && row.player?.first_name.substring(0, 1) + ". "}{" "}
          {!entryList && row.player?.last_name}
          {!entryList && " (" + row.player?.nationality + ")"}
        </Text>
      </View>
    </View>
  );
}
