import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import { UIContext } from "../../contexts/UIContext";
import EventList from "./EventList";
import { useRouteMatch } from "react-router-dom";
import { Switch } from "react-router-dom";
import ScoringEvent from "./ScoringEvent";
import Report from "../Report/Report";
import { Route } from "react-router-dom";
const Main = styled.div`
  display: flex;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Scoring() {
  const { getRound, getGroup } = useContext(DataContext);
  const { selectedCompetition } = useContext(UIContext);
  const [rounds, setRounds] = useState();
  const [groups, setGroups] = useState();
  const [date, setDate] = useState("");
  const [groupNum, setGroupNum] = useState("1");

  useEffect(() => {
    getData();
  }, [selectedCompetition]);

  function getData() {
    getRound().then((data) => {
      setRounds(
        JSON.parse(
          JSON.stringify([
            ...(data.filter(
              (g) => g.competition?._id === selectedCompetition?._id
            ) || []),
          ])
        )
      );
    });
    getGroup().then((data) => {
      setGroups(
        JSON.parse(
          JSON.stringify(
            [...(data || [])]
              .filter((g) => g.competition?._id === selectedCompetition?._id)
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
          )
        )
      );
    });
  }

  let match = useRouteMatch();

  return (
    <Main>
      <EventList rounds={rounds} />
      <div style={{ marginLeft: "260px", width: "100%" }}>
        <div>
          <Panel style={{ marginTop: "1em" }}>
            <PanelTitle title={"Scoring"}></PanelTitle>
            <PanelContent>
              <Switch>
                <Route path={`${match.path}/:roundId/:groupId`}>
                  <ScoringEvent
                    rounds={rounds}
                    groups={groups}
                    updateReport={() => {
                      setDate(Date.now());
                    }}
                    groupNum={groupNum}
                  />
                </Route>
                <Route path={`${match.path}/:roundId`}>
                  <ScoringEvent
                    rounds={rounds}
                    groups={groups}
                    updateReport={() => {
                      setDate(Date.now());
                    }}
                    groupNum={groupNum}
                  />
                </Route>
              </Switch>
            </PanelContent>
          </Panel>
        </div>
        <Panel>
          <PanelTitle title={"Printing"}></PanelTitle>
          <PanelContent>
            <Switch>
              <Route path={`${match.path}/:roundId/:groupId`}>
                <Report
                  rounds={rounds}
                  groups={groups}
                  date={date}
                  groupNum={groupNum}
                />
              </Route>
            </Switch>
          </PanelContent>
        </Panel>
      </div>
    </Main>
  );
}
