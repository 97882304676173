import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import { Button } from "../../Theme/Hyper";
import { UIContext } from "../../contexts/UIContext";
import ScheduleEditor from "../Modals/ScheduleEditor";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function Schedule() {
  const { getRound } = useContext(DataContext);
  const [rounds, setRounds] = useState();
  const [selectedRound, setSelectedRound] = useState();
  const { selectedCompetition } = useContext(UIContext);
  useEffect(() => {
    getData();
  }, [selectedCompetition]);

  function getData() {
    getRound().then((data) => {
    
      setRounds([
        ...(data?.filter(
          (r) => r.competition?._id === selectedCompetition?._id
        ) || []),
      ]);
    });
  }

  return (
    <Main>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Qualifying"}>
          <Button
            green
            style={{ margin: "1em", width: "200px" }}
            onClick={() => {
              setSelectedRound({
                competition: selectedCompetition._id,
                type: "qualifying",
              });
            }}
          >
            + Create Event
          </Button>
        </PanelTitle>
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>Order</TH>
                <TH>Date</TH>
                <TH>Time</TH>
                <TH>Event name</TH>
              </tr>
            </thead>
            <tbody>
              {rounds
                ?.filter((r) => r.type === "qualifying")
                ?.sort((a,b) => {
                  return a.order- b.order
                })
                ?.map((round) => {
                  return (
                    <tr
                      onClick={() => {
                        setSelectedRound({ ...round });
                      }}
                    >
                      <td>{round._id}</td>
                      <td>{round?.order}</td>
                      <td>{round?.date}</td>
                      <td>{round?.time}</td>
                      <td>{round?.event?.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Final"}>
          <Button
            green
            style={{ margin: "1em", width: "200px" }}
            onClick={() => {
              setSelectedRound(
                rounds.find((r) => r.name === "final") || {
                  competition: selectedCompetition._id,
                  type: "final",
                }
              );
            }}
          >
            + Create Event
          </Button>
        </PanelTitle>
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>Order</TH>
                <TH>Date</TH>
                <TH>Time</TH>
                <TH>Event name</TH>
              </tr>
            </thead>
            <tbody>
              {rounds
                ?.filter((r) => r.type === "final")
                ?.sort((a,b) => {
                  return a.order- b.order
                })
                ?.map((round) => {
                  return (
                    <tr
                      onClick={() => {
                        setSelectedRound({ ...round });
                      }}
                    >
                      <td>{round._id}</td>
                      <td>{round?.order}</td>
                      <td>{round?.date}</td>
                      <td>{round?.time}</td>
                      <td>{round?.event?.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedRound && (
        <ScheduleEditor
          scheduleEvent={selectedRound}
          selectEvent={(s) => {
            setSelectedRound(s);
          }}
          onCancel={() => {
            setSelectedRound();
          }}
          onSave={() => {
            setSelectedRound();
            getData();
          }}
        />
      )}
    </Main>
  );
}
