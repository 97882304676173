import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import CompetitionEditor from "../Modals/CompetitionEditor";
import { Button } from "../../Theme/Hyper";
import PlayerEditor from "../Modals/PlayerEditor";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function Players() {
  const { getPlayer } = useContext(DataContext);
  const [players, setPlayers] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getPlayer().then((data) => {
      setPlayers(
        [...(data || [])].sort((a, b) => {
          if (a.last_name < b.last_name) {
            return -1;
          }
          if (a.last_name > b.last_name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }
  return (
    <>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedPlayer({});
        }}
      >
        + Create Player
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Player List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>Brian ID</TH>
                <TH>First Name</TH>
                <TH>Last Name</TH>
                <TH>Nationality</TH>
                <TH>Nationality Web</TH>
              </tr>
            </thead>
            <tbody>
              {players?.map((player) => {
                return (
                  <tr
                    onClick={() => {
                      setSelectedPlayer(player);
                    }}
                  >
                    <td>{player._id}</td>
                    <td>{player.shirt_number}</td>
                    <td>{player.first_name}</td>
                    <td>{player.last_name}</td>
                    <td>{player.nationality}</td>
                    <td>{player.nationality_web}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedPlayer && (
        <PlayerEditor
          player={selectedPlayer}
          selectPlayer={(s) => {
            setSelectedPlayer(s);
          }}
          onCancel={() => {
            setSelectedPlayer();
          }}
          onSave={() => {
            setSelectedPlayer();
            getData();
          }}
        />
      )}
    </>
  );
}
