import React from "react";
import styled from "styled-components";
import Groups from "../Groups/Groups";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <Groups />
    </Main>
  );
}
