import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";
import { Button } from "../../../Theme/Hyper";
import TimeDistanceModal from "./TimeDistanceModal";
import { TiTick,TiTimes } from "react-icons/ti";
import Input from "../../UI/Form/Input";
const Main = styled.div`
  margin-top: 2em;
  border-color: #dee2e6;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px !important;
  width: 100%;
  overflow: hidden;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
  }
  > tbody > tr:hover {
    > td:nth-child(1) {
      border-left: 5px solid #14ec8e;
    }

    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Loading({ group, round, standings, updateReport }) {
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [scores, setScores] = useState();
  const [status, setStatus] = useState();
  const [time, setTime] = useState();
  const [roundStandings, setRoundStandings] = useState();
  const { updateScores, clearScores, getScores, getRoundStandings, updateRoundStandings } =
    useContext(DataContext);

  useEffect(() => {
    getData();
  }, [group, round]);

  function getData(update = true) {
    getRoundStandings().then((data) => {
      let obj = data?.find(
        (d) => d.round?._id === round?._id && d.group?._id === group?._id
      );
      if (obj) {
        setRoundStandings(obj);
        setStatus(obj.status);
        setTime(obj.time);
      }
    });
    if (update) {
      getScores().then((data) => {
        setScores(
          JSON.parse(
            JSON.stringify(data?.filter((s) => s.round?._id === round?._id) || [])
          )
        );
      }
      );
    }
    updateReport();
  }
  return (
    <Main>
      <Input
        value={time || ""}
        onChange={(e) => {
          setTime(e.currentTarget.value);
        }}
      />
      <Button
        onClick={() => {
          updateRoundStandings({
            data: {
              _id: roundStandings._id,
              time: time,
            },
          });
        }}
      >
        Save Time
      </Button>
      <Table>
        <thead>
          <tr>
            <TH style={{ width: "30px" }}></TH>
            <TH style={{ width: "60px" }}>Order</TH>
            <TH style={{ width: "250px" }}>Name</TH>
            {round?.event?.objects?.map((object) => {
              return <><TH style={{ width: "90px" }}>{object.name}</TH><TH style={{ width: "90px" }}></TH></>;
            })}
            <TH style={{ width: "200px" }}>Distance</TH>
            <TH style={{ width: "200px" }}>Time</TH>
            <TH style={{ width: "100%", textAlign: "center" }}>

            </TH>
          </tr>
        </thead>
        <tbody>
          {group?.players
            ?.sort((a, b) => {
              let scoring_a = scores?.find(
                (s) => s.player?._id === a._id
              )?.scoring;
              let scoring_b = scores?.find(
                (s) => s.player?._id === b._id
              )?.scoring;

              if ((scoring_a?.order || 999) > (scoring_b?.order || 999)) {
                return 1;
              } else if (
                (scoring_a?.order || 999) < (scoring_b?.order || 999)
              ) {
                return -1;
              }

              // Else go to the 2nd item
              if (scoring_a?.lane < scoring_b?.lane) {
                return -1;
              } else if (scoring_a?.lane > scoring_b?.lane) {
                return 1;
              } else {
                // nothing to split them
                return 0;
              }
            })
            ?.map((player, index) => {
              let scoring = scores?.find(
                (s) => s.player?._id === player._id
              ) || {
                round: round?._id,
                player: player?._id,
              };

              let standing = standings?.positions?.find(
                (s) => s.player === player._id
              );
              return (
                <tr
                  onClick={() => {
                    setSelectedPlayer(player);
                  }}
                >
                  <td>{scoring?.scoring?.lane}</td>
                  <td>{scoring?.scoring?.order}</td>
                  <td>
                    {player.first_name} {player.last_name}
                  </td>
                  {round?.event?.objects?.map((object, index) => {
                    return (<>
                      <td>
                        <Tick
                          group={group}
                          number={index + 1}
                          scoring={scoring}
                          player={player}
                          round={round}
                          updateScore={(ev) => {
                            let new_scores = [...scores];
                            let index = new_scores?.findIndex(
                              (s) => s.player?._id === player._id
                            )
                            if (index > -1) {
                              new_scores[index] = ev;
                            }
                            setScores(new_scores);
                            // updateScores({
                            //   data: {
                            //     ...ev,
                            //     group: group._id,
                            //     round: round._id,
                            //     player: player._id,
                            //   },
                            // }).then(() => {
                            //   getData();
                            // });
                          }}
                        />
                      </td>
                       <td>
                       <Cross
                              group={group}
                              number={index + 1}
                              scoring={scoring}
                              player={player}
                              round={round}
                              updateScore={(ev) => {
                                let new_scores = [...scores];
                            let index = new_scores?.findIndex(
                              (s) => s.player?._id === player._id
                            )
                            if (index > -1) {
                              new_scores[index] = ev;
                            }
                            setScores(new_scores);
                              }}
                            />
                            </td>
                     </>
                    );
                  })}
                  <td>{scoring?.scoring?.distance}</td>
                  <td>{scoring?.scoring?.time}</td>


                  <td style={{ textAlign: "center" }}>
                    <div style={{ display: 'flex' }}>
                      <Button
                        green
                        onClick={(e) => {
                          e.stopPropagation();
                          let index = scores?.findIndex(
                            (s) => s.player?._id === player._id
                          )

                          updateScores({
                            data: {
                              ...scores[index],
                              group: group._id,
                              round: round._id,
                              player: player._id,
                            },
                          }).then(() => {
                            getData(false);
                          });
                        }}
                      >
                        Save
                      </Button>
                      <Button

                        onClick={(e) => {
                          e.stopPropagation();


                          clearScores({
                            data: {
                              group: group._id,
                              round: round._id,
                              player: player._id,
                            },
                          }).then(() => {
                            getData();
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {selectedPlayer && (
        <TimeDistanceModal
          player={selectedPlayer}
          scoring={JSON.parse(
            JSON.stringify(
              scores?.find((s) => s.player?._id === selectedPlayer._id) || {
                round: round?._id,
                player: selectedPlayer?._id,
              }
            )
          )}
          group={group}
          round={round}
          selectPlayer={(player) => {
            setSelectedPlayer(player);
            getData();
          }}
        />
      )}
      <div>
        <Button
          green={!status || status === "not_finished"}
          onClick={() => {
            updateRoundStandings({
              data: {
                _id: roundStandings._id,
                status: "not_finished",
              },
            });
            setStatus("not_finished");
          }}
        >
          Not Finished
        </Button>
        <Button
          green={status === "finished"}
          onClick={() => {
            updateRoundStandings({
              data: {
                _id: roundStandings._id,
                status: "finished",
              },
            });
            setStatus("finished");
          }}
        >
          Finished
        </Button>
      </div>
    </Main>
  );
}

function Tick({ number, updateScore, scoring }) {
  return (
    <Button
      style={{ width: '50px' }}
      green={
        scoring?.scoring?.objects?.find((o) => o.number === number)?.success
      }
      onClick={(e) => {
       
        e.stopPropagation();
        let newScoring = { ...JSON.parse(JSON.stringify(scoring)) };
        let old_value =
          newScoring?.scoring?.objects?.find((o) => o.number === number)
            ?.success || false;
        newScoring.scoring = newScoring?.scoring || {};
        newScoring.scoring.objects = newScoring?.scoring?.objects || [];
        newScoring.scoring.objects = newScoring.scoring.objects.filter(
          (o) => o.number !== number
        );
        newScoring.scoring.objects.push({
          number: number,
          success: !old_value,
        });
        updateScore(newScoring);
      }}
    >
      <TiTick />
    </Button>
  );
}

function Cross({ number, updateScore, scoring }) {
  return (
    <Button
      style={{ marginRight: "5px", minWidth: "30px" }}
      red={
        scoring?.scoring?.objects?.find((o) => o.number === number)?.success===false
      }
      onClick={(e) => {
        e.stopPropagation();
        let newScoring = { ...JSON.parse(JSON.stringify(scoring)) };
        let old_value =
          newScoring?.scoring?.objects?.find((o) => o.number === number)
            ?.success || false;
        newScoring.scoring = newScoring?.scoring || {};
        newScoring.scoring.objects = newScoring?.scoring?.objects || [];
        newScoring.scoring.objects = newScoring.scoring.objects.filter(
          (o) => o.number !== number
        );
        newScoring.scoring.objects.push({
          number: number,
          success: false,
        });
        updateScore(newScoring);
      }}
    >
      <TiTimes />
    </Button>
  );
}