import React, { useState, useContext, useEffect } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Headshot from "../UI/Profile/Headshot";
import moment from "moment";
import PlayerSeasonStats from "./PlayerSeasonStats";
import PlayerGameStats from "./PlayerGameStats";
import styled from "styled-components";
import Stories from "./Stories";
import SelectUI from "../UI/Form/SelectUI";
import { DataContext } from "../../contexts/DataContext";
import { AiOutlineCloudUpload } from "react-icons/ai";

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const StatsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: ${(props) => props.width};
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function PlayerEdit({ player, team, onChange }) {
  const [view, setView] = useState(4);
  const [countries, setCountries] = useState();
  const { getCountry } = useContext(DataContext);

  useEffect(() => {
    getCountry({}).then((countries) => {
      setCountries(countries);
    });
  }, []);

  function updatePlayer(player) {
    onChange(player);
  }

  function toFeet(n) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + "''";
  }

  return (
    <Grid>
      <Grid>
        {/* <Grid.Unit size={1 / 8}>
          <Headshot
            image={
              "___MEDIA/FOOTBALL/PLAYER_IMAGES/" +
              team?.variables?.team_photos_folder +
              "/HOME" +
              player.head_shots.find((p) => p.type === "standard")?.url
            }
            style={{ width: "120px", height: "120px" }}
          />
        </Grid.Unit> */}
        <Grid.Unit size={7 / 8}>
          {(player?.type?.toLowerCase() === "manager" ||
            player?.type?.toLowerCase() === "coach") && (
            <Grid>
              <Grid.Unit size={1 / 6}>
                <Input
                  value={player?.title}
                  title="Title"
                  onChange={(e) => {
                    let newPlayer = { ...player, title: e.target.value };

                    updatePlayer({ ...newPlayer });
                  }}
                />
              </Grid.Unit>
            </Grid>
          )}
          <Grid>
            <Grid>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player?.first_name}
                  title="First Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player,
                      first_name: e.target.value,
                    };
                    newPlayer.first_name_upper =
                      newPlayer.first_name.toUpperCase();
                    updatePlayer({ ...newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player?.last_name}
                  title="Last Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player,
                      last_name: e.target.value,
                    };
                    newPlayer.last_name_upper =
                      newPlayer.last_name.toUpperCase();
                    updatePlayer({ ...newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player?.first_name_upper}
                  title="First Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player,
                      first_name_upper: e.target.value,
                    };

                    updatePlayer({ ...newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player?.last_name_upper}
                  title="Last Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player,
                      last_name_upper: e.target.value,
                    };

                    updatePlayer({ ...newPlayer });
                  }}
                />
              </Grid.Unit>
              {player?.type?.toLowerCase() !== "manager" &&
                player?.type?.toLowerCase() !== "coach" && (
                  <>
                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.shirt_number}
                        title="Shirt Number"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player,
                            shirt_number: e.target.value,
                          };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>

                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.nationality}
                        title="Nationality"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player,
                            nationality: e.target.value,
                          };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>

                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.nationality_web}
                        title="Nationality Web"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player,
                            nationality_web: e.target.value,
                          };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>

                    <Grid.Unit size={1 / 4}>
                      <FileUpload
                        team={team}
                        id="player"
                        label="Player image"
                        value={player?.image || ""}
                        onChange={(filename) => {
                          let newPlayer = {
                            ...player,
                            image: filename,
                          };

                          updatePlayer({ ...newPlayer });
                        }}
                      />
                    </Grid.Unit>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid.Unit>
      </Grid>
    </Grid>
  );
}

function FileUpload({ team, value, onChange, label, id }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url = process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
      formData.append(file.name, team?.name + "/" + file.name);
    });

    fetch(`${project_serevr_url}/api/assets/images/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(
        team?.name?.replace(/ /g, "_") + "/" + files[0].name.replace(/ /g, "_")
      );
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        id={id}
        type="file"
        name="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById(id).click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <img
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/images/" +
                userId +
                "/" +
                encodeURIComponent(value)
              }
              alt="Player"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}
