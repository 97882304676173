import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import EventEdit from "../Events/EventEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function EventEditor({ event, onSave, onCancel, selectEvent }) {
  const { updateEvent, deleteEvent } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={event}
      onCancel={() => {
        selectEvent();
        onCancel();
      }}
    >
      {event && (
        <Panel style={{ marginTop: "-25%" }}>
          <PanelTitle title={"Event Edit"}>
            <div>{event._id}</div>
          </PanelTitle>
          <PanelContent>
            <EventEdit
              event={event}
              onChange={(s) => {
                selectEvent(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={event._id}
            onDelete={() => {
              deleteEvent({ data: event }).then(() => {
                selectEvent();
                onSave();
              });
            }}
            onSave={() => {
              let newEvent = JSON.parse(JSON.stringify(event));
              if (
                newEvent.event_type !== "loading" &&
                newEvent.event_type !== "stone_off" &&
                newEvent.event_type !== "max_weight"
              ) {
                delete newEvent.objects;
              }
              updateEvent({ data: newEvent }).then(() => {
                selectEvent();
                onSave();
              });
            }}
            onCancel={() => {
              selectEvent();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
