import styled from "styled-components";
import { FaPlusCircle } from "react-icons/fa";

export default {
  primaryBackgroundColour: "#0e1219",
  secondaryBackgroundColour: "#1f222b",
  primaryFontColour: "#cacaca",
  dividerColour: "#2d2d2d",
  hoverColour: "#191b21",
  mainFontSize: ".75em",
  borderColour: "#101010",
  panelColour: "#353a48",
  panelBorderColour: "#4f566b",
  highlightColour: "#db0a41",
  highlightColourLight: "#d63660",
  textItemColour: "#ffeb00",
  imageItemColour: "#00ffa1",
  videoItemColour: "#c000ff",
  groupItemColour: "#00d0ff;",
};

export const H1 = styled.h1`
  font-size: 2em;
  font-weight: 100;
  margin-bottom: 0.5em;
`;

export const H2 = styled.h2`
  margin-top: 0.5em;
  font-size: 1em;
  font-weight: 100;
`;

export const PlusIcon = styled(FaPlusCircle)`
  margin-right: 0.5em;
  font-size: 1.5em;
  display: flex;
  align-items: center;
`;

export const ScrollBar = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d1d4d6;
    border-radius: 2px;
    overflow: hidden;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #14ec8e;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #11ca79;
  }
`;
export const Button = styled.div`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.orange ? "#ec8214" : props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red || props.orange ? "#fff" : "")};
  font-size: 0.8em;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#dadcde"};
  }
`;
