import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import GroupEdit from "../Groups/GroupEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function GroupEditor({ group, onSave, onCancel, selectGroup }) {
  const { updateGroup, deleteGroup } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={group}
      onCancel={() => {
        selectGroup();
        onCancel();
      }}
    >
      {group && (
        <Panel style={{ marginTop: "-25%" }}>
          <PanelTitle title={"Group Edit"}>
            <div>{group._id}</div>
          </PanelTitle>
          <PanelContent>
            <GroupEdit
              group={group}
              onChange={(s) => {
                selectGroup(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={group._id}
            onDelete={() => {
              deleteGroup({ data: group }).then(() => {
                selectGroup();
                onSave();
              });
            }}
            onSave={() => {
              updateGroup({
                data: { ...group, players: group?.players?.map((p) => p._id) },
              }).then(() => {
                selectGroup();
                onSave();
              });
            }}
            onCancel={() => {
              selectGroup();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
