import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";
const Main = styled.div`
  width: 250px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;

  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;
function EventList({ rounds }) {
  return (
    <Main>
      <div>Qualifying</div>
      <Ul>
        {rounds
          ?.filter((r) => r.type === "qualifying")
          ?.sort((a, b) => a.order - b.order)
          ?.map((round) => {
            return (
              <>
                <Li>
                  <A to={"/wsm/scoring/" + round._id}>{round.event?.name}</A>
                </Li>
              </>
            );
          })}
      </Ul>
      <div>Final</div>
      <Ul>
        {rounds
          ?.filter((r) => r.type === "final")
          ?.sort((a, b) => a.order - b.order)
          ?.map((round) => {
            return (
              <>
                <Li>
                  <A to={"/wsm/scoring/" + round._id}>{round.event?.name}</A>
                </Li>
              </>
            );
          })}
      </Ul>
    </Main>
  );
}

export default EventList;
