import React, { useContext, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import font from "../../../Fonts/open-sans-regular.ttf";
import Standings from "./Standings";
import { DataContext } from "../../../contexts/DataContext";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: font,
      fontWeight: "400",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    margin: 20,
    marginLeft: 40,
    marginBottom: 0,
  },
  section: {
    flexGrow: 1,
  },
  column: {
    flexDirection: "columns",
  },
  half: {
    marginTop: 15,
    height: 1,
  },
  results: {
    flexGrow: 0.5,
  },
  eventTitle: {
    fontSize: 10,
    margin: 5,
  },
  table: {
    display: "table",
    width: "auto",
    fontFamily: "Helvetica",
    fontSize: 8,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    padding: "3px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  heading: {
    fontWeight: "400",
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
  },
  order: {
    width: 40,
  },
  lane: {
    width: 30,
  },
  athlete: {
    width: 125,
  },
  object: {
    width: 255,
    textAlign: "center",
    fontSize: 7,
  },
  objects: {
    width: 55,
  },
  distance: {
    width: 65,
  },
  time: {
    width: 50,
  },
  position: {
    width: 30,
  },
  points: {
    width: 120,
  },
  pointsSplit: {
    width: 120 / 3,
    textAlign: "center",
  },
  tick: { width: 10, height: 10, marginLeft: 30 },
  notes: {
    border: "1 solid black",
    borderTop: "none",
    width: 410,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
  },
});

// Create Document Component
export default function LoadingReport({
  selectedGroup,
  selectedEvent,
  rounds,
  selectedRound,
  entryList,
  date,
}) {
  const [scores, setScores] = useState();
  const [roundStandings, setRoundStandings] = useState();
  const { getScores, getRoundStandings } = useContext(DataContext);

  useEffect(() => {
    getData();
  }, [selectedGroup, date]);

  function getData() {
    getRoundStandings().then((data) => {
      setRoundStandings(
        data?.find(
          (d) =>
            d.round?._id === selectedRound?._id &&
            d.group?._id === selectedGroup?._id
        )
      );
      getScores().then((data) => {
        setScores(
          JSON.parse(
            JSON.stringify(
              data?.filter((s) => s.round?._id === selectedRound?._id) || []
            )
          )
        );
      });
    });
  }

  let players = selectedGroup?.players?.map((player, index) => {
    let scoring = scores?.find(
      (g) => g.round?._id === selectedRound._id && g.player?._id === player?._id
    );

    return { ...player, scoring: scoring?.scoring };
  });

  styles.object.width = 220 / selectedRound?.event?.objects?.length;
  styles.tick.marginLeft =
    220 / selectedRound?.event?.objects?.length / 2 - styles.tick.width;

  return (
    <>
      {selectedRound && (
        <PDFViewer style={{ width: "100%", height: "1000px" }}>
          <Document
            title={
              selectedRound?.competition?.name +
              (selectedGroup?.name === '6' ? ' Final' : " - Group " +
              selectedGroup?.name) +
              " - Event " +
              selectedRound?.order +
              " " +
              selectedRound?.event?.name + (entryList ? ' - Orders' : '')
            }
            author="Hyper"
            date={date}
          >
            <Page
              size="A4"
              style={styles.page}
              orientation="landscape"
              wrap={false}
            >
              <View style={styles.column}>
                <View style={styles.row}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitle}>
                      {selectedRound?.competition?.name}
                    </Text>
                    <Text style={styles.eventTitle}>
                      {selectedRound.type
                        .replace("qualifying", "Qualifying")
                        .replace("final", "Final")}{" "}
                      Round
                      {selectedRound.type === "qualifying" && (
                        <span> - Group {selectedGroup?.name}</span>
                      )}
                    </Text>
                    <Text style={styles.eventTitle}>
                      Event {selectedRound?.order}: {selectedRound?.event?.name}
                    </Text>
                  </View>
                  <View style={[styles.section, { marginLeft: 150 }]}>
                    <Text style={styles.eventTitle}>
                      Date: {moment(selectedRound?.date,'DD/MM/YYYY').format("MMMM DD, YYYY")}
                    </Text>
                    <Text style={styles.eventTitle}>
                      Location: {selectedRound?.competition?.location}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.row,
                    styles.half,
                    {
                      height: players?.length * 30,
                    },
                  ]}
                >
                  <View style={styles.table}>
                    <View style={[styles.row, styles.heading]}>
                      <View style={[styles.cell, styles.order]}></View>
                      <View style={[styles.cell, styles.lane]}></View>
                      <View
                        style={[
                          styles.cell,
                          styles.athlete,
                          {
                            border: "1 solid black",
                          },
                        ]}
                      >
                        <Text>ATHLETE</Text>
                      </View>
                      {selectedRound?.event?.objects?.map((object) => {
                        return (
                          <View
                            style={[
                              styles.cell,
                              styles.object,
                              {
                                border: "1 solid black",
                                borderLeft: "none",
                              },
                            ]}
                          >
                            <Text>{object.name}</Text>
                          </View>
                        );
                      })}
                      {selectedRound?.event?.name?.indexOf("REIGN") === -1 &&
                        selectedRound?.event?.name?.indexOf("Log Lift") ===
                        -1 &&(
                          <>
                            <View
                              style={[
                                styles.cell,
                                styles.distance,
                                {
                                  border: "1 solid black",
                                  borderLeft: "none",
                                  textAlign: "center",
                                },
                              ]}
                            >
                              <Text>DISTANCE (m)</Text>
                            </View>
                            <View
                              style={[
                                styles.cell,
                                styles.time,
                                {
                                  border: "1 solid black",
                                  borderLeft: "none",
                                  textAlign: "center",
                                },
                              ]}
                            >
                              <Text>TIME</Text>
                            </View>
                          </>
                        )}
                      <View
                        style={[
                          styles.cell,
                          styles.position,

                          {
                            border: "1 solid black",
                            borderLeft: "none",
                          },
                        ]}
                      >
                        <Text>POS</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          styles.points,

                          {
                            border: "1 solid black",
                            borderLeft: "none",
                          },
                        ]}
                      >
                        <Text>POINTS</Text>
                      </View>
                    </View>
                    <View style={[styles.row, styles.heading]}>
                      <View
                        style={[
                          styles.cell,
                          styles.order,
                          {
                            border: "1 solid black",
                            textAlign: "center",
                          },
                        ]}
                      >
                        <Text>ORDER</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          styles.lane,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderRight: "none",

                            textAlign: "center",
                          },
                        ]}
                      >
                        <Text>LANE</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          styles.athlete,

                          {
                            border: "1 solid black",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <Text></Text>
                      </View>

                      {selectedRound?.event?.objects?.map((object, index) => {
                        return (
                          <View
                            style={[
                              styles.cell,
                              styles.object,
                              {
                                border: "1 solid black",
                                borderLeft: "none",
                                borderTop: "none",
                              },
                            ]}
                          >
                            <Text></Text>
                          </View>
                        );
                      })}
                      {selectedRound?.event?.name?.indexOf("REIGN") === -1 &&
                        selectedRound?.event?.name?.indexOf("Log Lift") ===
                        -1 && (
                          <>
                            <View
                              style={[
                                styles.cell,
                                styles.distance,
                                {
                                  border: "1 solid black",
                                  borderLeft: "none",
                                  borderTop: "none",
                                },
                              ]}
                            >
                              <Text></Text>
                            </View>

                            <View
                              style={[
                                styles.cell,
                                styles.time,
                                {
                                  border: "1 solid black",
                                  borderLeft: "none",
                                  borderTop: "none",
                                },
                              ]}
                            >
                              <Text></Text>
                            </View>
                          </>
                        )}
                      <View
                        style={[
                          styles.cell,
                          styles.position,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <Text></Text>
                      </View>

                      <View
                        style={[
                          styles.row,
                          styles.points,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                              borderLeft: "none",
                              borderRight: "1 solid black",
                            },
                          ]}
                        >
                          <Text>Event</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                              borderLeft: "none",
                              borderRight: "1 solid black",
                            },
                          ]}
                        >
                          <Text>Entering</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                            },
                          ]}
                        >
                          <Text>Total</Text>
                        </View>
                      </View>
                    </View>
                    {players?.map((player) => {
                      return (
                        <TableRow
                          player={player}
                          standings={roundStandings}
                          selectedEvent={selectedEvent}
                          selectedRound={selectedRound}
                          rounds={rounds}
                          entryList={entryList}
                        />
                      );
                    })}
                  </View>
                </View>
                <View
                  style={[
                    styles.row,
                    styles.half,
                    {
                      height: players?.length * 25,
                      marginTop: players?.length >= 10 ? -70 : 15,
                    },
                  ]}
                >
                  <View style={[styles.section, styles.results]}>
                    <Text style={styles.eventTitle}>Results</Text>
                    <View style={styles.table}>
                      <View style={[styles.row, styles.heading]}>
                        <View
                          style={[
                            styles.cell,
                            styles.position,

                            {
                              border: "1 solid black",
                              borderRight: "none",
                            },
                          ]}
                        >
                          <Text>POS</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.lane,

                            {
                              border: "1 solid black",
                              borderRight: "none",
                            },
                          ]}
                        >
                          <Text></Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.lane,

                            {
                              border: "1 solid black",
                              borderRight: "none",
                            },
                          ]}
                        >
                          <Text></Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.athlete,
                            {
                              border: "1 solid black",
                            },
                          ]}
                        >
                          <Text>ATHLETE</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.objects,
                            {
                              border: "1 solid black",
                              borderLeft: "none",
                            },
                          ]}
                        >
                          <Text>OBJECTS</Text>
                        </View>

                        <View
                          style={[
                            styles.cell,
                            styles.distance,
                            {
                              border: "1 solid black",
                              borderLeft: "none",
                            },
                          ]}
                        >
                          <Text>DISTANCE (m)</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.time,
                            {
                              border: "1 solid black",
                              borderLeft: "none",
                              textAlign: "center",
                            },
                          ]}
                        >
                          <Text>TIME</Text>
                        </View>

                        <View
                          style={[
                            styles.cell,
                            styles.points,

                            {
                              border: "1 solid black",
                              borderLeft: "none",
                              width: 40,
                            },
                          ]}
                        >
                          <Text>POINTS</Text>
                        </View>
                      </View>

                      {roundStandings?.players
                        ?.filter((p) => {
                          return (
                            (p.status === "WD" && p.overall_status === "WD") ||
                            p.overall_status !== "WD"
                          );
                        })
                        ?.sort((a, b) => {
                          if (a?.position || 999 > b?.position || 999) {
                            return 1;
                          } else if (a?.position || 999 < b?.position || 999) {
                            return -1;
                          }

                          let player_a = a.player;
                          let player_b = b.player;

                          if (player_a?.last_name > player_b?.last_name) {
                            return 1;
                          } else if (
                            player_a?.last_name < player_b?.last_name
                          ) {
                            return -1;
                          }
                          if (player_a?.first_name > player_b?.first_name) {
                            return 1;
                          } else if (
                            player_a?.first_name < player_b?.first_name
                          ) {
                            return -1;
                          }
                          return 0;
                        })
                        ?.map((player) => {
                          return (
                            <TableResultsRow
                              player={players?.find(
                                (s) => s._id === player.player._id
                              )}
                              standings={player}
                              selectedEvent={selectedRound.event}
                              selectedRound={selectedRound}
                              rounds={rounds}
                              entryList={entryList}
                            />
                          );
                        })}
                      <View
                        style={[
                          styles.notes,
                          {
                            width: 425,
                          },
                        ]}
                      >
                        <Text>
                          {!entryList &&
                            roundStandings?.players
                              ?.filter((p) => {
                                return p.status === "WD";
                              })
                              ?.map((player) => {
                                player = players?.find(
                                  (s) => s._id === player.player._id
                                );
                                return (
                                  "WD    " +
                                  player.first_name?.[0] +
                                  ". " +
                                  player.last_name +
                                  " withdrew from competition after event"
                                );
                              })}
                          {(!entryList &&
                            selectedEvent &&
                            selectedEvent.scoring &&
                            selectedEvent.scoring.find(
                              (g) => g.group === selectedGroup._id
                            ) &&
                            selectedEvent.scoring.find(
                              (g) => g.group === selectedGroup._id
                            ).results_notes) ||
                            " "}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      {
                        marginLeft: 20,
                      },
                    ]}
                  >
                    <Standings
                      standings={JSON.parse(
                        JSON.stringify(roundStandings || {})
                      )}
                      event={selectedEvent}
                      round={selectedRound}
                      rounds={rounds}
                      selectedRound={selectedRound}
                      entryList={entryList}
                    />
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
}

function TableRow({
  player,
  standings,
  selectedEvent,
  rounds,
  entryList,
  selectedRound,
}) {
  let previousRounds;
  rounds?.forEach((round) => {
    let currentRound = round?.events?.find(
      (r) => r.event._id === selectedEvent._id
    );
    if (currentRound) {
      previousRounds = round.events.filter((r) => r.order < currentRound.order);
    }
  });

  let standing = standings?.players?.find((p) => p.player._id === player._id);
  let previousPoints = standing?.entering_points || 0;
  let points = standing?.points || 0;

  let total = standing?.total_points || 0;

  return (
    <>
      {player &&
        (standing?.overall_status !== "WD" || standing?.status === "WD") && (
          <View style={[styles.row]}>
            <View
              style={[
                styles.cell,
                styles.order,
                {
                  border: "none",
                  borderLeft: "1 solid black",
                  borderRight: "1 solid black",
                  borderBottom: "1 solid black",
                },
              ]}
            >
              <Text>{player.scoring && player.scoring.order}</Text>
            </View>
            <View
              style={[
                styles.cell,
                styles.lane,
                {
                  border: "none",
                  borderBottom: "1 solid black",
                },
              ]}
            >
              <Text>{player.scoring && player.scoring.lane}</Text>
            </View>
            <View
              style={[
                styles.cell,
                styles.athlete,
                {
                  border: "1 solid black",
                  borderTop: "none",
                },
              ]}
            >
              <Text>
                {player.first_name} {player.last_name} ({player.nationality})
              </Text>
            </View>
            {selectedRound?.event?.objects?.map((object, index) => {
              return (
                <View
                  style={[
                    styles.cell,
                    styles.object,
                    {
                      border: "1 solid black",
                      borderLeft: "none",
                      borderTop: "none",
                    },
                  ]}
                >
                  {!entryList &&
                    player.scoring?.objects?.find((o) => o.number === index + 1)
                      ?.success && (
                      <Image src="/tick.jpg" style={styles.tick} />
                    )}
                     {!entryList &&
                    player.scoring?.objects?.find((o) => o.number === index + 1)
                      ?.success===false && (
                        <Image src="/cross.jpg" style={styles.tick} />
                    )}
                  {!entryList &&
                    (selectedRound?.event?.name?.indexOf("REIGN") > -1 ||
                      selectedRound?.event?.name?.indexOf("Log Lift") > -1) &&
                    player.scoring.objects &&
                    index ==
                    player.scoring.objects.filter((o) => o.success).length &&
                    player.scoring && <Text>X</Text>}
                </View>
              );
            })}
            {selectedRound?.event?.name?.indexOf("REIGN") === -1 &&
              selectedRound?.event?.name?.indexOf("Log Lift") === -1 && (
                <>
                  <View
                    style={[
                      styles.cell,
                      styles.distance,
                      {
                        border: "1 solid black",
                        borderLeft: "none",
                        borderTop: "none",
                      },
                    ]}
                  >
                    <Text>
                      {!entryList && player.scoring && player.scoring.distance}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      styles.time,
                      {
                        border: "1 solid black",
                        borderLeft: "none",
                        borderTop: "none",
                      },
                    ]}
                  >
                    <Text>
                      {!entryList && player.scoring && player.scoring.time}
                    </Text>
                  </View>
                </>
              )}
            <View
              style={[
                styles.cell,
                styles.position,
                {
                  border: "1 solid black",
                  borderLeft: "none",
                  borderTop: "none",
                },
              ]}
            >
              <Text>
                {!entryList && standing?.tied == true ? "T" : ""}
                {!entryList && standing?.position}
              </Text>
            </View>
            <View
              style={[
                styles.row,
                styles.points,
                {
                  border: "1 solid black",
                  borderLeft: "none",
                  borderTop: "none",
                },
              ]}
            >
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                    borderLeft: "none",
                    borderRight: "1 solid black",
                  },
                ]}
              >
                <Text>{!entryList && points?.toFixed(1)}</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                    borderLeft: "none",
                    borderRight: "1 solid black",
                  },
                ]}
              >
                <Text>{previousPoints.toFixed(1)}</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                  },
                ]}
              >
                <Text>{!entryList && total?.toFixed(1)}</Text>
              </View>
            </View>
          </View>
        )}
    </>
  );
}

function TableResultsRow({
  player,
  standings,
  selectedEvent,
  rounds,
  round,
  entryList,
  scoring,
}) {
  let points = standings?.points;

  return (
    <>
      <View style={[styles.row]}>
        <View
          style={[
            styles.cell,
            styles.position,
            {
              border: "none",
              borderLeft: "1 solid black",
              borderBottom: "1 solid black",
            },
          ]}
        >
          <Text>
            {!entryList && standings?.tied == true ? "T" : ""}
            {!entryList && standings?.position}
          </Text>
        </View>
        <View
          style={[
            styles.cell,
            styles.lane,
            {
              border: "none",
              borderLeft: "1 solid black",
              borderBottom: "1 solid black",
            },
          ]}
        >
          <Text>{!entryList && standings?.status}</Text>
        </View>
        <View
          style={[
            styles.cell,
            styles.lane,
            {
              border: "none",
              borderLeft: "1 solid black",
              borderBottom: "1 solid black",
              fontSize: 7,
            },
          ]}
        >
          <Text>
            {!entryList && player?.shirt_number ? player?.shirt_number : ""}
          </Text>
        </View>
        <View
          style={[
            styles.cell,
            styles.athlete,
            {
              border: "1 solid black",
              borderTop: "none",
            },
          ]}
        >
          <Text>
            {!entryList && player?.first_name?.substring(0, 1) + ". "}{" "}
            {!entryList && player?.last_name}{" "}
            {!entryList && "(" + player?.nationality + ")"}
          </Text>
        </View>
        <View
          style={[
            styles.cell,
            styles.objects,
            {
              border: "1 solid black",
              borderLeft: "none",
              borderTop: "none",
            },
          ]}
        >
          <Text>
            {!entryList &&
              player?.scoring?.objects?.filter((o) => o.success)?.length}
          </Text>
        </View>
        {selectedEvent?.name?.indexOf("REIGN") === -1 &&
          selectedEvent?.name?.indexOf("Log Lift") === -1 && (
            <>
              <View
                style={[
                  styles.cell,
                  styles.distance,
                  {
                    border: "1 solid black",
                    borderLeft: "none",
                    borderTop: "none",
                  },
                ]}
              >
                <Text>{!entryList && player?.scoring?.distance}</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.time,
                  {
                    border: "1 solid black",
                    borderLeft: "none",
                    borderTop: "none",
                  },
                ]}
              >
                <Text>{!entryList && player?.scoring?.time}</Text>
              </View>
            </>
          )}
        <View
          style={[
            styles.cell,
            {
              border: "none",

              borderRight: "1 solid black",
              borderBottom: "1 solid black",
              width: 40,
            },
          ]}
        >
          <Text>{!entryList && points?.toFixed(1)}</Text>
        </View>
      </View>
    </>
  );
}
