import React, { useContext, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import font from "../../../Fonts/open-sans-regular.ttf";
import Standings from "./Standings";
import { DataContext } from "../../../contexts/DataContext";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: font,
      fontWeight: "400",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    margin: 20,
    marginLeft: 40,
  },
  section: {
    flexGrow: 1,
  },
  column: {
    flexDirection: "columns",
  },
  half: {
    marginTop: 15,
    height: 250,
  },
  results: {
    flexGrow: 0.5,
  },
  eventTitle: {
    fontSize: 10,
    margin: 5,
  },
  table: {
    display: "table",
    width: "auto",
    fontFamily: "Helvetica",
    fontSize: 8,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    padding: "3px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  heading: {
    fontWeight: "400",
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
  },
  order: {
    width: 40,
  },
  lane: {
    width: 30,
  },
  athlete: {
    width: 140,
  },
  object: {
    width: 250,
    textAlign: "center",
  },
  distance: {
    width: 65,
  },
  time: {
    width: 50,
  },
  position: {
    width: 30,
  },
  points: {
    width: 120,
  },
  pointsSplit: {
    width: 120 / 3,
    textAlign: "center",
  },
  tick: { width: 10, height: 10, marginLeft: 30 },
  notes: {
    border: "1 solid black",
    borderTop: "none",
    width: 420,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
  },
});

// Create Document Component
export default function MaxDistanceReport({
  selectedGroup,
  selectedEvent,
  rounds,
  selectedRound,
  entryList,
  date,
}) {
  const [scores, setScores] = useState();
  const [roundStandings, setRoundStandings] = useState();
  const [leaders, setLeaders] = useState();
  const { getScores, getRoundStandings, getResults } = useContext(DataContext);

  

  useEffect(() => {
    getData();
  }, [selectedGroup, date, selectedEvent]);

  function getData() {

    getResults().then((data) => {
      let leaders = [];


      data.forEach((c, index) => {
        
        let standings = c?.rounds?.find(
          (r) => r.round?.event?._id === selectedRound?.event?._id
        )?.standings;

        leaders.push(...(standings || []));
      });
      setLeaders(leaders)
    }).catch(err => {
      debugger
    })

  }

  function sort(a, b) {
    let reps_1 = parseFloat(a.scoring?.distance) || 0;
    let reps_2 = parseFloat(b.scoring?.distance) || 0;
    if (reps_1 < reps_2) {
      return 1;
    } else if (reps_1 > reps_2) {
      return -1;
    }
  }

  let players = leaders?.map((player, index) => {

    let scoring = scores?.find(
      (g) => g.round?._id === selectedRound._id && g.player?._id === player?._id
    );

    return { ...player?.player, scoring: player?.scoring , overall_status: player?.overall_status, overall_status: player?.status};
  })?.sort(sort);

  styles.object.width = 220 / selectedRound?.event?.objects?.length;
  styles.tick.marginLeft =
    220 / selectedRound?.event?.objects?.length / 2 - styles.tick.width;

  return (
    <>
      {selectedRound && (
        <PDFViewer style={{ width: "100%", height: "1000px" }}>
          <Document
           title={
            selectedRound?.competition?.name +
            (selectedGroup?.name === '6' ? ' Final' : " - Overall " ) +
            " - Event " +
            selectedRound?.order +
            " " +
            selectedRound?.event?.name
          }
            author="Hyper"
            date={date}
          >
            <Page size="A4" style={styles.page} orientation="portrait">
              <View style={styles.column}>
                <View style={styles.row}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitle}>
                      {selectedRound?.competition?.name}
                    </Text>
                    <Text style={styles.eventTitle}>
                      {selectedRound.type
                        .replace("qualifying", "Qualifying")
                        .replace("final", "Final")}{" "}
                      Round
                      {selectedRound.type === "qualifying" && (
                        <span> - Overall</span>
                      )}
                    </Text>
                    <Text style={styles.eventTitle}>
                      Event {selectedRound?.order}: {selectedRound?.event?.name}
                    </Text>
                  </View>
                  <View style={[styles.section, { marginLeft: 50 }]}>
                    <Text style={styles.eventTitle}>
                      Date:{" "}
                      {moment(selectedRound?.date, "DD/MM/YYYY").format(
                        "MMMM DD, YYYY"
                      )}
                    </Text>
                    <Text style={styles.eventTitle}>
                      Location: {selectedRound?.competition?.location}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.row,
                    styles.half,
                    {
                      height: players?.length * 30,
                    },
                  ]}
                >
                  <View style={styles.table}>
                    <View style={[styles.row, styles.heading]}>
                      <View style={[styles.cell, styles.order]}></View>
                      {/* <View style={[styles.cell, styles.lane]}></View> */}
                      <View
                        style={[
                          styles.cell,
                          styles.athlete,
                          {
                            border: "1 solid black",
                          },
                        ]}
                      >
                        <Text>ATHLETE</Text>
                      </View>
                      {selectedRound?.event?.objects?.map((object) => {
                        return (
                          <View
                            style={[
                              styles.cell,
                              styles.object,
                              {
                                border: "1 solid black",
                                borderLeft: "none",
                              },
                            ]}
                          >
                            <Text>{object.name}</Text>
                          </View>
                        );
                      })}
                      {
                        <>
                          <View
                            style={[
                              styles.cell,
                              styles.distance,
                              {
                                border: "1 solid black",
                                borderLeft: "none",
                                textAlign: "left",
                              },
                            ]}
                          >
                            <Text>
                              {selectedRound?.event?.unit_name?.toUpperCase() ||
                                "REPS"}
                            </Text>
                          </View>
                        </>
                      }
                      {/* <View
                        style={[
                          styles.cell,
                          styles.position,

                          {
                            border: "1 solid black",
                            borderLeft: "none",
                          },
                        ]}
                      >
                        <Text>POS</Text>
                      </View> */}
                      {/* <View
                        style={[
                          styles.cell,
                          styles.points,

                          {
                            border: "1 solid black",
                            borderLeft: "none",
                          },
                        ]}
                      >
                        <Text>POINTS</Text>
                      </View> */}
                    </View>
                    <View style={[styles.row, styles.heading]}>
                      <View
                        style={[
                          styles.cell,
                          styles.order,
                          {
                            border: "1 solid black",
                            textAlign: "center",
                          },
                        ]}
                      >
                        <Text>POS</Text>
                      </View>
                      {/* <View
                        style={[
                          styles.cell,
                          styles.lane,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderRight: "none",

                            textAlign: "center",
                          },
                        ]}
                      >
                        <Text>LANE</Text>
                      </View> */}
                      <View
                        style={[
                          styles.cell,
                          styles.athlete,

                          {
                            border: "1 solid black",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <Text></Text>
                      </View>

                      <View
                        style={[
                          styles.cell,
                          styles.distance,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <Text></Text>
                      </View>

                      {/* <View
                        style={[
                          styles.cell,
                          styles.position,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <Text></Text>
                      </View> */}

                      {/* <View
                        style={[
                          styles.row,
                          styles.points,
                          {
                            border: "1 solid black",
                            borderLeft: "none",
                            borderTop: "none",
                          },
                        ]}
                      >
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                              borderLeft: "none",
                              borderRight: "1 solid black",
                            },
                          ]}
                        >
                          <Text>Event</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                              borderLeft: "none",
                              borderRight: "1 solid black",
                            },
                          ]}
                        >
                          <Text>Entering</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            styles.pointsSplit,
                            {
                              border: "none",
                            },
                          ]}
                        >
                          <Text>Total</Text>
                        </View>
                      </View> */}
                    </View>
                    {players?.map((player, index) => {
                      let tied = index >0 && players?.[index]?.scoring?.distance === players?.[index-1]?.scoring?.distance;
                      try {
                      if (players?.[index]?.scoring?.distance === players?.[index+1]?.scoring?.distance) {
                        tied = true;
                      }}catch(err){}
                      let tied_index = players?.findIndex((p )=>{
                        return p.scoring?.distance === player?.scoring?.distance
                      })
                      return (
                        <TableRow
                          player={player}
                          standings={roundStandings}
                          selectedEvent={selectedEvent}
                          selectedRound={selectedRound}
                          rounds={rounds}
                          entryList={entryList}
                          index={index+1}
                          tied={tied}
                          tied_index={tied_index+1}
                        />
                      );
                    })}
                  </View>
                </View>
              
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
}

function TableRow({
  player,
  standings,
  selectedEvent,
  rounds,
  entryList,
  selectedRound,
  index,
  tied,
  tied_index
}) {
  let standing = standings?.players?.find((p) => p.player._id === player._id);
  let previousPoints = standing?.entering_points || 0;
  let points = standing?.points || 0;

  let total = standing?.total_points || 0;

  return (
    <>
      {player &&
        (standing?.overall_status !== "WD" || standing?.status === "WD") && (
          <View style={[styles.row]}>
            <View
              style={[
                styles.cell,
                styles.order,
                {
                  border: "none",
                  borderLeft: "1 solid black",
                  borderRight: "1 solid black",
                  borderBottom: "1 solid black",
                },
              ]}
            >
              <Text>{tied ? 'T' +tied_index : index}</Text>
            </View>
            {/* <View
              style={[
                styles.cell,
                styles.lane,
                {
                  border: "none",
                  borderBottom: "1 solid black",
                },
              ]}
            >
              <Text>{player?.scoring?.lane}</Text>
            </View> */}
            <View
              style={[
                styles.cell,
                styles.athlete,
                {
                  border: "1 solid black",
                  borderTop: "none",
                },
              ]}
            >
              <Text>
                {player?.first_name} {player?.last_name} ({player?.nationality})
              </Text>
            </View>

            <View
              style={[
                styles.cell,
                styles.distance,
                {
                  border: "1 solid black",
                  borderLeft: "none",
                  borderTop: "none",
                },
              ]}
            >
              <Text>{!entryList && player?.scoring?.distance}</Text>
            </View>

            {/* <View
              style={[
                styles.cell,
                styles.position,
                {
                  border: "1 solid black",
                  borderLeft: "none",
                  borderTop: "none",
                },
              ]}
            >
              <Text>
                {!entryList && standing?.tied == true ? "T" : ""}
                {!entryList && standing?.position}
              </Text>
            </View> */}
            {/* <View
              style={[
                styles.row,
                styles.points,
                {
                  border: "1 solid black",
                  borderLeft: "none",
                  borderTop: "none",
                },
              ]}
            >
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                    borderLeft: "none",
                    borderRight: "1 solid black",
                  },
                ]}
              >
                <Text>{!entryList && points.toFixed(1)}</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                    borderLeft: "none",
                    borderRight: "1 solid black",
                  },
                ]}
              >
                <Text>{previousPoints.toFixed(1)}</Text>
              </View>
              <View
                style={[
                  styles.cell,
                  styles.pointsSplit,
                  {
                    border: "none",
                  },
                ]}
              >
                <Text>{!entryList && total.toFixed(1)}</Text>
              </View>
            </View> */}
          </View>
        )}
    </>
  );
}

function TableResultsRow({
  player,
  standings,
  selectedEvent,
  rounds,
  round,
  entryList,
  scoring,
}) {
  let points = standings?.points;

  return (
    <>
      {player?.withdrew != 1 && (
        <View style={[styles.row]}>
          <View
            style={[
              styles.cell,
              styles.position,
              {
                border: "none",
                borderLeft: "1 solid black",
                borderBottom: "1 solid black",
              },
            ]}
          >
            <Text>
              {!entryList && standings?.tied == true ? "T" : ""}
              {!entryList && standings?.position}
            </Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.position,
              {
                border: "none",
                borderLeft: "1 solid black",
                borderBottom: "1 solid black",
              },
            ]}
          >
            <Text>{!entryList && standings?.status}</Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.position,
              {
                border: "none",
                borderLeft: "1 solid black",
                borderBottom: "1 solid black",

                fontSize: 7,
              },
            ]}
          >
            <Text>
              {!entryList && player?.shirt_number ? player?.shirt_number : ""}
            </Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.athlete,
              {
                border: "1 solid black",
                borderTop: "none",
              },
            ]}
          >
            <Text>
              {!entryList && player?.first_name?.substring(0, 1) + ". "}{" "}
              {!entryList && player?.last_name}{" "}
              {!entryList && "(" + player?.nationality + ")"}
            </Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.distance,
              {
                border: "1 solid black",
                borderLeft: "none",
                borderTop: "none",
              },
            ]}
          >
            <Text>{!entryList && player?.scoring?.reps}</Text>
          </View>

          <View
            style={[
              styles.cell,
              {
                border: "none",

                borderRight: "1 solid black",
                borderBottom: "1 solid black",
                width: 50,
              },
            ]}
          >
            <Text>{!entryList && points.toFixed(1)}</Text>
          </View>
        </View>
      )}
    </>
  );
}
