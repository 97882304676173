import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";


const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 100px;
`;
// Create Document Component
export default function Notes() {

 return <Main><h1>Qualifying rounds</h1>
 <p>Heats 1 - 4 orders based on previous event, if tied go back. 5th event order based on standings.</p>
 <p>TODO: Automatically show Qualified for final if they have more points lead than is available in remaining events. (eg. Singleton 2024 - Group 4 - Viking press)</p>
 <p>TODO: Check why Nana and Heinla wasnt sorted correctly after stone off - they were tied overall but should have been sorted on countback of prev event</p>

 <h1>Final rounds</h1><p>Heats 1 - 5 orders based on previous event, if tied go back. 6th event order based on standings.</p></Main>
}
