import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../Theme/Hyper";

function DisplayControl({ groups, rounds, display, updateDisplay, getData }) {
  const { roundId } = useParams();
  return (
    <div style={{ display: 'flex' }}>
      <Button
        green={display && display.holding === true}
        onClick={() => {
          let round = rounds.find((r) => r._id === roundId);
          updateDisplay({
            data: {
              event: round.event?._id,
              round: round._id,
              competition: round.competition._id,
              group: "",
              holding: true,
            },
          }).then(() => {
            getData()
          });
        }}
      >
        SHOW HOLDING
      </Button>
      <Button
        green={display && display.holding === false}
        onClick={() => {
          let round = rounds.find((r) => r._id === roundId);
          updateDisplay({
            data: {
              event: round.event?._id,
              round: round._id,
              competition: round.competition._id,
              group: "",
              holding: false,
            },
          }).then(() => {
            getData()
          });;
        }}
      >
        HIDE HOLDING
      </Button>

      {groups?.map((ev) => {
        return (
          <Button
            green={display?.group?._id === ev._id}
            onClick={() => {
              let round = rounds.find((r) => r._id === roundId);

              updateDisplay({
                data: {
                  event: round.event?._id,
                  round: round._id,
                  competition: round.competition._id,
                  group: ev._id,
                  holding: false,
                },
              }).then(() => {
                getData()
              });;
              //setSelectedGroup(ev);
            }}
          >
            {ev.name}
          </Button>
        );
      })}
    </div>
  );
}

export default DisplayControl;
