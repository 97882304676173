import React, { useContext, useEffect, useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import SelectUI from "../UI/Form/SelectUI";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { DataContext } from "../../contexts/DataContext";

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody {
    max-height: 400px;
    overflow-y: auto;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function GroupEdit({ group, onChange }) {
  const { getPlayer } = useContext(DataContext);
  const [players, setPlayers] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getPlayer().then((data) => {
      setPlayers(
        [...(data || [])].sort((a, b) => {
          if (a.last_name < b.last_name) {
            return -1;
          }
          if (a.last_name > b.last_name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }

  function updateGroup(group) {
    onChange(group);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={group?.name}
          title="Name"
          onChange={(e) => {
            let newGroup = { ...group, name: e.target.value };
            updateGroup(newGroup);
          }}
        />
      </Grid.Unit>
      <Grid
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid.Unit
          size={5 / 12}
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <Table>
            <thead>
              <tr>
                <TH>Name</TH>
              </tr>
            </thead>
            <tbody>
              {players
                ?.filter(
                  (p) =>
                    !group?.players ||
                    group?.players?.findIndex(
                      (player) => player._id === p._id
                    ) === -1
                )
                ?.map((player) => {
                  return (
                    <tr
                      onClick={() => {
                        let newGroup = { ...JSON.parse(JSON.stringify(group)) };
                        newGroup.players = newGroup.players || [];

                        newGroup.players.push(player);
                        updateGroup(newGroup);
                      }}
                    >
                      <td>
                        {player.first_name} {player.last_name}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Grid.Unit>
        <Grid.Unit size={5 / 12}>
          <Table>
            <thead>
              <tr>
                <TH>Name</TH>
              </tr>
            </thead>
            <tbody>
              {group?.players?.map((player) => {
                return (
                  <tr
                    onClick={() => {
                      let newGroup = { ...JSON.parse(JSON.stringify(group)) };
                      newGroup.players = newGroup.players.filter(
                        (p) => p._id !== player._id
                      );
                      updateGroup(newGroup);
                    }}
                  >
                    <td>
                      {player.first_name} {player.last_name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Grid.Unit>
      </Grid>
    </Grid>
  );
}
