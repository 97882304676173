import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext } from "../../../contexts/UIContext";
import { DataContext } from "../../../contexts/DataContext";
import { Link } from "react-router-dom";
import HyperLogo from "../../../Images/HyperLogo.png";
import { UserContext } from "../../../contexts/UserContext";

const Main = styled.div``;

const Content = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 60px;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  bottom: 0;
  z-index: 100;
  width: 230px;
  background-color: #1d2939;
  transition: all 0.2s ease-in-out;
  padding-top: 40px;
`;

const Ul = styled.ul`
  margin: 0;

  list-style-type: none;
  padding-left: 0;
  transition: all 0.2s ease-in-out;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 0.5em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;

const A_STATIC = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;

const LogoContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  width: 230px;
  height: 60px;
  background-color: #141c27;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
  justify-content: center;
`;
const Logo = styled.img`
  height: 20px;
`;
export default function Sidebar() {
  const { navigationView, setNavigationView, showSideBar } =
    useContext(UIContext);
  const { user } = useContext(UserContext);

  return (
    <Main>
      <LogoContainer expanded={showSideBar}>
        <Logo src={HyperLogo} />
      </LogoContainer>
      <Content expanded={showSideBar}>
        <Ul>
          <Li>
            <A_STATIC
              href="#"
              bold="true"
              onClick={() => {
                document.location = window.location.origin + "/football";
              }}
            >
              Football
              {/* <FiChevronDown /> */}
            </A_STATIC>
          </Li>
          <Li>
            <A_STATIC
              href="#"
              bold="true"
              onClick={() => {
                document.location = window.location.origin + "/rugby";
              }}
            >
              Rugby
              {/* <FiChevronDown /> */}
            </A_STATIC>
          </Li>
          <Li>
            <A_STATIC
              href="#"
              bold="true"
              onClick={() => {
                document.location = window.location.origin + "/netball";
              }}
            >
              Netball
              {/* <FiChevronDown /> */}
            </A_STATIC>
          </Li>
          <Li>
            <A
              href="#"
              bold="true"
              selected={
                true ||
                (navigationView && navigationView.split("/")[0] === "WSM")
              }
              onClick={() => {
                setNavigationView("WSM/");
              }}
              to={""}
            >
              WSM
            </A>
            <Ul>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Scoring");
                }}
              >
                <A
                  to="/WSM/Scoring"
                  selected={navigationView && navigationView === "WSM/Scoring"}
                  selectedbg={
                    navigationView && navigationView === "WSM/Scoring"
                  }
                >
                  Scoring
                </A>
              </Li>

              <Li
                onClick={() => {
                  setNavigationView("WSM/Players");
                }}
              >
                <A
                  to="/WSM/Players"
                  selected={navigationView && navigationView === "WSM/Players"}
                  selectedbg={
                    navigationView && navigationView === "WSM/Players"
                  }
                >
                  Players
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Countries");
                }}
              >
                <A
                  to="/WSM/Countries"
                  selected={
                    navigationView && navigationView === "WSM/Countries"
                  }
                  selectedbg={
                    navigationView && navigationView === "WSM/Countries"
                  }
                >
                  Countries
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Events");
                }}
              >
                <A
                  to="/WSM/Events"
                  selected={navigationView && navigationView === "WSM/Events"}
                  selectedbg={navigationView && navigationView === "WSM/Events"}
                >
                  Events
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Groups");
                }}
              >
                <A
                  to="/WSM/Groups"
                  selected={navigationView && navigationView === "WSM/Groups"}
                  selectedbg={navigationView && navigationView === "WSM/Groups"}
                >
                  Groups
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Schedule");
                }}
              >
                <A
                  to="/WSM/Schedule"
                  selected={navigationView && navigationView === "WSM/Schedule"}
                  selectedbg={
                    navigationView && navigationView === "WSM/Schedule"
                  }
                >
                  Schedule
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Competitions");
                }}
              >
                <A
                  to="/WSM/Competitions"
                  selected={
                    navigationView && navigationView === "WSM/Competitions"
                  }
                  selectedbg={
                    navigationView && navigationView === "WSM/Competitions"
                  }
                >
                  Competitions
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Display");
                }}
              >
                <A
                  to="/WSM/Display"
                  selected={navigationView && navigationView === "WSM/Display"}
                  selectedbg={
                    navigationView && navigationView === "WSM/Display"
                  }
                >
                  Display
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("WSM/Notes");
                }}
              >
                <A
                  to="/WSM/Notes"
                  selected={navigationView && navigationView === "WSM/Notes"}
                  selectedbg={
                    navigationView && navigationView === "WSM/Notes"
                  }
                >
                  Notes
                </A>
              </Li>
            </Ul>
          </Li>
        </Ul>
      </Content>
    </Main>
  );
}
