import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import CountryEditor from "../Modals/CountryEditor";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function CountriesList() {
  const { getCountry } = useContext(DataContext);
  const [countries, setCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getCountry({}).then((data) => {
      setCountries(data);
    });
  }
  return (
    <>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Countries List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                {/* <TH>OPTA ID</TH> */}
                <TH>Country</TH>
                <TH>Code</TH>
                {/* <TH>OPTA Code</TH> */}
              </tr>
            </thead>
            <tbody>
              {countries &&
                countries.map((country) => {
                  return (
                    <tr
                      onClick={() => {
                        //setSelectedCountry(country);
                      }}
                    >
                      <td>{country._id}</td>
                      {/* <td>{country.opta_ID}</td> */}
                      <td>
                        {country.name ||
                          country.opta_name ||
                          country.sotic_name}
                      </td>
                      <td>{country.opta_code || country.sotic_code}</td>
                      {/* <td>{country.opta_code}</td> */}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedCountry && (
        <CountryEditor
          country={selectedCountry}
          selectCountry={(s) => {
            setSelectedCountry(s);
          }}
          onCancel={() => {
            setSelectedCountry();
          }}
          onSave={() => {
            setSelectedCountry();
            getData();
          }}
        />
      )}
    </>
  );
}
