import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import TimeDistance from "./EventTypes/TimeDistance";
import Reps from "./EventTypes/Reps";
import MaxTime from "./EventTypes/MaxTime";
import MaxWeight from "./EventTypes/MaxWeight";
import Loading from "./EventTypes/Loading";
import StoneOff from "./EventTypes/StoneOff";
import Input from "../UI/Form/Input";
import { Link } from "react-router-dom";
import KegToss from "./EventTypes/KegToss";
import MaxDistance from "./EventTypes/MaxDistance";

const Row = styled.div`
  display: flex;
`;
const A = styled(Link)`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red ? "#fff" : "")};
  font-size: 0.8em;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#dadcde"};
  }
`;

function ScoringEvent({ rounds, groups, updateReport }) {
  const { roundId, groupId } = useParams();
  let round = rounds?.find((r) => r._id === roundId);
  return (
    <div>
      <h2>{round?.event?.name}</h2>
      <Row>
        {groups
          ?.filter(
            (g) =>
              (round?.type === "qualifying" && parseInt(g?.name) <= 5) ||
              (round?.type === "final" && parseInt(g?.name) === 6)
          )
          ?.map((group) => {
            return (
              <A
                green={groupId === group?._id}
                to={"/wsm/scoring/" + round?._id + "/" + group?._id}
              >
                {group.name}
              </A>
            );
          })}
           <A
                green={groupId == "overall"}
                to={"/wsm/scoring/" + round?._id + "/overall"}
              >
                Overall
              </A>
      </Row>
      <Row>
        {round?.event?.event_type === "keg_toss" && (
          <KegToss
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
        {round?.event?.event_type === "loading" && (
          <Loading
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
        {round?.event?.event_type === "reps" && (
          <Reps
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
        {round?.event?.event_type === "reps_and_time" && (
          <Reps
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
        {round?.event?.event_type === "max_time" && (
          <MaxTime
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
        {round?.event?.event_type === "max_weight" && (
          <MaxWeight
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}

        {round?.event?.event_type === "time_and_distance" && (
          <TimeDistance
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}

{round?.event?.event_type === "max_distance" && (
          <MaxDistance
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}

        {round?.event?.event_type === "stone_off" && (
          <StoneOff
            group={groups?.find((g) => g._id === groupId)}
            round={round}
            updateReport={updateReport}
          />
        )}
      </Row>
      <Row>
        <Input
          placeholder="Result Notes"
          value={""}
          onChange={(e) => {}}
        ></Input>
      </Row>
      <Row>
        <Input
          placeholder="Standings Notes"
          value={""}
          onChange={(e) => {}}
        ></Input>
      </Row>
    </div>
  );
}

export default ScoringEvent;
