import React, { useContext, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import styled from "styled-components";
import moment from "moment";

import Modal from "../../UI/Modal/Modal";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import Progress from "../../UI/Progress/Progress";
import StageContent from "../../UI/Progress/StageContent";
import KickOff from "./KickOff";
import Select from "react-select";
import TeamSelect from "./TeamSelect";
import { UIContext } from "../../../contexts/UIContext";
import VenueSelect from "./VenueSelect";
import CompetitionSelect from "./CompetitionSelect";

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function CreateFixture({ show, onCancel, onSaved }) {
  const { createFixture } = useContext(DataContext);
  const [round, setRound] = useState(1);
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment("15:00", "HH:mm"));
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();
  const [competition, setCompetition] = useState();
  const [season, setSeason] = useState();
  const [venue, setVenue] = useState();
  const { selectedSeason } = useContext(UIContext);

  return (
    <Modal
      show={show}
      onCancel={() => {
        onCancel();
      }}
    >
      {show && (
        <Panel style={{ minWidth: "50vw", minHeight: "500px" }}>
          <PanelTitle title={"Create Fixture"}></PanelTitle>
          <PanelContent style={{ width: "100%", flex: "1" }}>
            <Progress
              stages={["Competition", "Kick-Off", "Teams", "Stadium"]}
              onSave={() => {
                createFixture({
                  fixture: {
                    competition: season.competition._id,
                    season: season._id,
                    home_team: homeTeam._id,
                    away_team: awayTeam._id,
                    date: moment(date).format("YYYY-MM-DD") + "Z",
                    time: time.format("HH:mm"),
                    description: homeTeam.name + " v " + awayTeam.name,
                    stadium: venue._id,
                  },
                }).then(() => {
                  onSaved();
                });
              }}
            >
              <StageContent>
                <CompetitionSelect
                  competition={competition}
                  season={season}
                  onChange={(competition) => {
                    setCompetition(competition);
                  }}
                  onChangeSeason={(season) => {
                    setSeason(season);
                  }}
                ></CompetitionSelect>
              </StageContent>
              <StageContent>
                <KickOff
                  date={date}
                  time={time}
                  onChangeDate={(date) => {
                    setDate(date);
                  }}
                  onChangeTime={(time) => {
                    setTime(time);
                  }}
                ></KickOff>
              </StageContent>
              <StageContent>
                <TeamSelect
                  season={season}
                  homeTeam={homeTeam}
                  awayTeam={awayTeam}
                  onChangeHomeTeam={(team) => {
                    setHomeTeam(team);

                    setVenue(team.stadium);
                  }}
                  onChangeAwayTeam={setAwayTeam}
                />
              </StageContent>
              <StageContent>
                <VenueSelect stadium={venue} onChange={setVenue} />
              </StageContent>
            </Progress>
          </PanelContent>
        </Panel>
      )}
    </Modal>
  );
}
