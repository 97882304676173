import React, { useContext, useEffect, useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import SelectUI from "../UI/Form/SelectUI";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { DataContext } from "../../contexts/DataContext";
import Calendar from "react-calendar";
import "../../Calendar.css";
import Select from "react-select";
import moment from "moment";
import { UIContext } from "../../contexts/UIContext";
const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CalendarDiv = styled(Calendar)``;

const Time = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function ScheduleEdit({ event, onChange }) {
  const { getEvent } = useContext(DataContext);
  const { selectedCompetition } = useContext(UIContext);
  const [events, setEvents] = useState();
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getEvent().then((data) => {
      setEvents(
        [...(data || [])]
          ?.filter((e) => e.competition?._id === selectedCompetition._id)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
    });
  }

  function updateEvent(event) {
    onChange(event);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={event?.order || ""}
          title="Order"
          onChange={(e) => {
            let newEvent = { ...event };
            newEvent.order = parseInt(e.target.value);
            updateEvent(newEvent);
          }}
        />
      </Grid.Unit>
      <Grid.Unit size={1 / 1}>
        <SelectUI
          value={{ label: event?.event?.name }}
          options={events?.map((e) => {
            return {
              value: e._id,
              label: e?.name,
            };
          })}
          placeholder="Select Event"
          onChange={(e) => {
            let newEvent = { ...event };
            newEvent.event = events?.find((ev) => ev._id === e);

            updateEvent(newEvent);
          }}
        />
        <CalendarDiv
          minDetail={"month"}
          formatShortWeekday={(locale, date) => moment(date).format("dd")}
          prev2Label={null}
          next2Label={null}
          onChange={(val) => {
            let newEvent = { ...event };
           
            newEvent.date = moment(val).format("DD/MM/YYYY");
            updateEvent(newEvent);
            //onChangeDate(val);
          }}
          value={
            event?.date
              ? new Date(moment(event?.date, "DD/MM/YYYY").valueOf())
              : new Date()
          }
        />
        <Time>
          <SelectContainer
            setSize={true}
            value={{
              value: moment(event?.time || "12:00", "HH:mm")?.hours(),
              label: moment(event?.time || "12:00", "HH:mm")
                ?.hours()
                ?.toString()
                ?.padStart(2, "0"),
            }}
            options={new Array(24).fill(1).map((item, index) => {
              return {
                value: index + 1,
                label: (index + 1).toString().padStart(2, "0"),
              };
            })}
            onChange={(e) => {
              let newDate = moment(event?.time || "12:00", "HH:mm").hours(
                e.value
              );
           
              let newEvent = { ...event };
              newEvent.time = newDate.format("HH:mm");
              updateEvent(newEvent);
            }}
            placeholder="HOUR"
          />
          :
          <SelectContainer
            setSize={true}
            value={{
              value: moment(event?.time || "12:00", "HH:mm")?.minutes(),
              label: moment(event?.time || "12:00", "HH:mm")
                ?.minutes()
                ?.toString()
                ?.padStart(2, "0"),
            }}
            options={new Array(60 / 5).fill(1).map((item, index) => {
              let min = index * 5;
              return {
                value: min,
                label: min.toString()?.padStart(2, "0"),
              };
            })}
            onChange={(e) => {
              let newDate = moment(event?.time || "12:00", "HH:mm").minutes(
                e.value
              );
              let newEvent = { ...event };
              newEvent.time = newDate.format("HH:mm");
              updateEvent(newEvent);
            }}
            placeholder="MINS"
          />
        </Time>
      </Grid.Unit>
    </Grid>
  );
}
