import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function TeamSelect({
  season,
  homeTeam,
  awayTeam,
  onChangeHomeTeam,
  onChangeAwayTeam,
}) {
  const [teams, setTeams] = useState();
  const { getTeams } = useContext(DataContext);
  useEffect(() => {
    setTeams(season?.teams);
  }, [season]);
  return (
    <Main>
      <SelectContainer
        value={
          homeTeam && {
            value: homeTeam._id,
            label: homeTeam.name,
          }
        }
        options={
          teams &&
          teams.map((t) => {
            return { value: t._id, label: t.name };
          })
        }
        onChange={(e) => {
          onChangeHomeTeam(teams.find((t) => t._id === e.value));
        }}
        placeholder="Select the home team"
      />
      v
      <SelectContainer
        value={
          awayTeam && {
            value: awayTeam._id,
            label: awayTeam.name,
          }
        }
        options={
          teams &&
          teams.map((t) => {
            return { value: t._id, label: t.name };
          })
        }
        onChange={(e) => {
          onChangeAwayTeam(teams.find((t) => t._id === e.value));
        }}
        placeholder="Select the away team"
      />
    </Main>
  );
}
