import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import LoadingReport from "./ReportTypes/LoadingReport";
import MaxTimeReport from "./ReportTypes/MaxTimeReport";
import MaxWeightReport from "./ReportTypes/MaxWeight";
import RepsReport from "./ReportTypes/RepsReport";
import RepsTimeReport from "./ReportTypes/RepsTimeReport";
import StoneOff from "./ReportTypes/StoneOff";
import TimeDistance from "./ReportTypes/TimeDistance";

import LoadingReportOverall from "../ReportOverall/ReportTypes/LoadingReport";
import MaxTimeReportOverall from "../ReportOverall/ReportTypes/MaxTimeReport";
import MaxWeightReportOverall from "../ReportOverall/ReportTypes/MaxWeight";
import RepsReportOverall from "../ReportOverall/ReportTypes/RepsReport";
import RepsTimeReportOverall from "../ReportOverall/ReportTypes/RepsTimeReport";
import StoneOffOverall from "../ReportOverall/ReportTypes/StoneOff";
import MaxDistanceReport from "../ReportOverall/ReportTypes/MaxDistanceReport";
import TimeDistanceReport from "../ReportOverall/ReportTypes/TimeDistanceReport";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
// Create Document Component
export default function Report({ rounds, groups, date }) {
  const { roundId, groupId } = useParams();
  let round = rounds?.find((r) => r._id === roundId);

  if (groupId !== 'overall') {
     return (
    <Main>
      {round?.event?.event_type === "loading" && (
        <>
          <LoadingReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <LoadingReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "reps" && (
        <>
          <RepsReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <RepsReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
        {round?.event?.event_type === "max_distance" && (
        <>
          <MaxDistanceReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <MaxDistanceReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "reps_and_time" && (
        <>
          <RepsTimeReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <RepsTimeReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "max_time" && (
        <>
          <MaxTimeReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <MaxTimeReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "max_weight" && (
        <>
          <MaxWeightReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <MaxWeightReport
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "time_and_distance" && (
        <>
          <TimeDistance
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <TimeDistance
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
      {round?.event?.event_type === "stone_off" && (
        <>
          <StoneOff
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
          />
          <StoneOff
            selectedGroup={groups?.find((g) => g._id === groupId)}
            selectedRound={round}
            date={date}
            entryList={true}
          />
        </>
      )}
    </Main>
  );
      } else {
        return (
          <Main>
             {round?.event?.event_type === "time_and_distance" && (
              <>
                <TimeDistanceReport
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
                
              </>
            )}
            {round?.event?.event_type === "loading" && (
              <>
                <LoadingReportOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
                
              </>
            )}
             {round?.event?.event_type === "max_distance" && (
              <>
                <MaxDistanceReport
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
               
              </>
            )}
            {round?.event?.event_type === "reps" && (
              <>
                <RepsReportOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
               
              </>
            )}
            {round?.event?.event_type === "reps_and_time" && (
              <>
                <RepsTimeReportOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
                
              </>
            )}
            {round?.event?.event_type === "max_time" && (
              <>
                <MaxTimeReportOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
               
              </>
            )}
            {round?.event?.event_type === "max_weight" && (
              <>
                <MaxWeightReportOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
               
              </>
            )}
           
            {round?.event?.event_type === "stone_off" && (
              <>
                <StoneOffOverall
                  selectedGroup={groups?.find((g) => g._id === groupId)}
                  selectedRound={round}
                  date={date}
                />
               
              </>
            )}
          </Main>
        );
      }

}
