import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import { Button } from "../../../Theme/Hyper";
import Input from "../../UI/Form/Input";
import NumberInput from "../../UI/Form/NumberInput";
import SavePanel from "../../UI/Form/SavePanel";
import Modal from "../../UI/Modal/Modal";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";

const Buttons = styled.div`
  display: flex;
  height: 40px;
  margin-top: 20px;
`;
export default function RepsModal({
  player,
  selectPlayer,
  scoring,
  group,
  round,
}) {
  const { updateScores } = useContext(DataContext);
  const [scoringObj, setScoringObj] = useState();

  useEffect(() => {
    let func = (e) => {
      if (e.keyCode === 13) {
        save();
      }
    };
    window.document.addEventListener("keydown", func);
    return () => {
      window.document.removeEventListener("keydown", func);
    };
  }, [scoringObj]);

  useEffect(() => {
    setScoringObj(scoring);
  }, [scoring]);

  const save = useCallback(() => {
    updateScores({
      data: {
        ...scoringObj,
        group: group._id,
        round: round._id,
        player: player._id,
      },
    }).then(() => {
      selectPlayer();
    });
  }, [scoringObj, selectPlayer, updateScores]);

  return (
    <Modal
      show={player}
      onCancel={() => {
        setScoringObj();
        selectPlayer();
      }}
    >
      {player && (
        <Panel
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              save();
            }
          }}
        >
          <PanelTitle
            title={player.first_name + " " + player.last_name}
          ></PanelTitle>
          <PanelContent>
            <Buttons>
              <Button
                green={scoringObj?.scoring?.lane === "L"}
                onClick={() => {
                  let newScoring = { ...scoringObj };
                  newScoring.scoring = newScoring.scoring || {};
                  newScoring.scoring.lane = "L";
                  setScoringObj(newScoring);
                }}
              >
                L
              </Button>
              <Button
                green={scoringObj?.scoring?.lane === "R"}
                onClick={() => {
                  let newScoring = { ...scoringObj };
                  newScoring.scoring = newScoring.scoring || {};
                  newScoring.scoring.lane = "R";
                  setScoringObj(newScoring);
                }}
              >
                R
              </Button>
              <Button
                green={scoringObj?.scoring?.lane === ""}
                onClick={() => {
                  let newScoring = { ...scoringObj };
                  newScoring.scoring = newScoring.scoring || {};
                  newScoring.scoring.lane = "";
                  setScoringObj(newScoring);
                }}
              >
                CLR
              </Button>
            </Buttons>
            <Input
              title="Order"
              value={scoringObj?.scoring?.order}
              onChange={(e) => {
                let newScoring = { ...scoringObj };
                newScoring.scoring = newScoring.scoring || {};
                newScoring.scoring.order = e.target.value;
                setScoringObj(newScoring);
              }}
            />

            <NumberInput
              title="Reps"
              // format="##.##"
              value={scoringObj?.scoring?.reps}
              onChange={(e) => {
                let newScoring = { ...scoringObj };
                newScoring.scoring = newScoring.scoring || {};
                newScoring.scoring.reps = e.target.value;
                setScoringObj(newScoring);
              }}
            />

            <NumberInput
              title="Time"
              format="#:##.##"
              value={scoringObj?.scoring?.time}
              onChange={(e) => {
                let newScoring = { ...scoringObj };
                newScoring.scoring = newScoring.scoring || {};
                newScoring.scoring.time = e.target.value;
                setScoringObj(newScoring);
              }}
            />

            <Input
              title="Withdrew"
              value={scoringObj?.scoring?.withdrew}
              onChange={(e) => {
                let newScoring = { ...scoringObj };
                newScoring.scoring = newScoring.scoring || {};
                newScoring.scoring.withdrew = e.target.value;
                setScoringObj(newScoring);
              }}
            />
            <Input
              title="Sort Override"
              value={scoringObj?.scoring?.sort_override}
              onChange={(e) => {
                let newScoring = { ...scoringObj };
                newScoring.scoring = newScoring.scoring || {};
                newScoring.scoring.sort_override = e.target.value;
                setScoringObj(newScoring);
              }}
            />
          </PanelContent>
          <SavePanel
            style={{ marginLeft: "auto" }}
            onSave={() => {
              save();
              setScoringObj();
            }}
            onCancel={() => {
              setScoringObj();
              selectPlayer();
            }}
          />
        </Panel>
      )}
    </Modal>
  );
}
